import React from 'react'

export class TextEdit extends React.Component {
    constructor(props) {
        super(props)
    }


    handleInputChange = event => {
        const { value } = event.target
        this.props.onChange(value)
    }

    render() {
        
        const { value, error, warning, label = '', placeholder='' } = this.props
        

        const input = <input type="text" className="form-control" value={ value } placeholder={ placeholder } aria-label="..." onChange={ this.handleInputChange } />

        const labelTag = label ? <label>{ label }</label> : null
        const errorMessage = error ? <div className="text-danger">{ error }</div> : null
        const warningMessage = warning ? <div className="text-warning">{ warning }</div> : null

        return <div className="form-group">
            { labelTag }
            { input }
            { errorMessage }
            { warningMessage }
        </div>
    }
}

