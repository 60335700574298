import React from 'react'
import { Application } from '../../Application'
import { FindVehiclePanel } from '../find'
import { SelectOpening } from './order/select-opening'
import { SelectGlassColor } from './order/select-color'

function InputImage(props) {
    return <div className="alert alert-info">
        <h3>Téléchargez une image du bris à réparer</h3>
        <div className="form-group">
            <input type="file" />
        </div>
    </div>
}

class OrderContactEdit extends React.Component {
    constructor(props) {
        super(props)
        this.state = { validation: {}}
    }

    handleSubmit = () => {
        const validation = {}
        const { name, phone, email, workType } = this.state

        if (!name || name < 3) validation['name'] = 'Vous devez spécifier votre nom'
        if (!phone || name < 7) validation['phone'] = 'Vous devez spécifier votre numéro de téléphone'
        if (!email || email < 7) validation['email'] = 'Vous devez spécifier votre courriel'
        if (!workType)  validation['workType'] = 'Vous devez spécifier le type de travail'

        
        if (Object.keys(validation).length === 0) {
            this.props.onSubmit({ name, phone, email, workType })
        } else {
            this.setState({ validation })
        }
    }

    render() {
        return <div>
            <h3>Type de travail</h3>
            <div className="radio">
                <label>
                    <input type="radio" name="workType" onClick={ () => this.setState({ workType: 'replace'}) }/>
                    Remplacement
                </label>
            </div>
            <div className="radio">
                <label>
                    <input type="radio" name="workType" onClick={ () => this.setState({ workType: 'repair'}) }/>
                    Réparation si possible
                </label>
            </div>
            { this.state.validation['workType'] ? <div className="text-danger">{ this.state.validation['workType'] }</div> : null }
            { this.state.workType === 'repair' ? <InputImage /> : '' }
            <h3>Vos coordonnées</h3>
            <div className="form-group">
                <label>Nom</label>
                <input type="text" className="form-control" onChange={ (event) => this.setState({ name: event.target.value }) } />
                { this.state.validation['name'] ? <div className="text-danger">{ this.state.validation['name'] }</div> : null}
            </div>
            <div className="form-group">
                <label>Courriel</label>
                <input type="email" className="form-control" onChange={ (event) => this.setState({ email: event.target.value }) } />
                { this.state.validation['email'] ? <div className="text-danger">{ this.state.validation['email'] }</div> : null}
            </div>
            <div className="form-group">
                <label>Téléphone</label>
                <input type="text" className="form-control" onChange={ (event) => this.setState({ phone: event.target.value }) } />
                { this.state.validation['phone'] ? <div className="text-danger">{ this.state.validation['phone'] }</div> : null}
            </div>
            <button className="btn btn-primary pull-right" onClick={ this.handleSubmit }>Continuer</button>
            <div className="clearfix"></div>
        </div>
    }
}


class VehicleGlassOrder extends React.Component {
    constructor(props) {
        super(props)

        this.state = { order: null, complete: false }
    }

    async saveOrder(order, complete = false) {
        const serializedOrder = JSON.stringify(order)

        const { success } = await Application.post({ path: '/glass-order/order', data: { order: serializedOrder, complete }})
        
        if (!success) 
            throw new Error(`Failed saving order ${ serializedOrder }`)

    }

    async componentDidMount() {
        const result = await Application.get({ path: '/glass-order/order', query: { json: true }})
        
        const { order } = result || null

        this.setState({ order })
    }

    handleClearVehicleSelection = async (event) => {
        const { order } = this.state
        order.vehicle = null

        await this.saveOrder(order)

        this.setState({ vehicle: null })
    }

    handleVehicleSelected = async (id, description) => {
        const { order } = this.state

        if (id && description)  {
            order.vehicle = { id, description }
        } else {
            order.vehicle = null
        }
        
        await this.saveOrder(order)

        this.setState({ order })
    }

    handleOpeningSelection = async (openingSelection) => {
        const { order } = this.state
        
        order.job = openingSelection

        const vehicle = order.vehicle.id
        const opening = openingSelection.opening.code
        const side = (openingSelection.side || {}).code
        const position = (openingSelection.position || {}).code

        const query = { vehicle, opening, side, position }
        
        const { colors = []} = await Application.get({ path: '/glass-order/colors', query})

        if (colors.length === 0) 
            throw new Error('No colors available')

        if (colors.length === 1) 
            order.job.color = colors[0]

        await this.saveOrder(order)

        this.setState({ order })
    }

    handleClearJob = async () => {
        const { order } = this.state
        
        order.job = null

        await this.saveOrder(order)

        this.setState({ order })
    }

    handleColorChanged = async (color) => {
        const { order } = this.state
        
        order.job.color = color

        await this.saveOrder(order)

        this.setState({ order })
    }

    handleContactSubmit = async ({ name, phone, email, workType }) => {
        const { order } = this.state
        
        order.details = { name, phone, email, workType }

        await this.saveOrder(order, true)

        this.setState({ complete: true })
    }

    render() { 
        const { order, complete } = this.state
        
        if (!order)
            return <div className="waiting"></div>
        
        if (!order.vehicle)
            return <div>
                <h2><strong>Spécifiez votre véhicule</strong></h2>
                <FindVehiclePanel onVehicleSelected={ this.handleVehicleSelected }/>
            </div>
        
        if (!order.job)
            return <div>
                <h2>Véhicule</h2>&nbsp;
                { this.renderVehicleSummary() }                
                <h2><strong>Spécifiez les travaux souhaités</strong></h2>
                <SelectOpening vehicle={ order.vehicle } onOpeningSelected={ this.handleOpeningSelection } />
            </div>
        
        if (!order.job.color) {
            return <div>
                <h2>Véhicule</h2>
                { this.renderVehicleSummary() }
                <h2>Travaux</h2>
                { this.renderJobSummary() }
                <h2><strong>Spécifiez la couleur désirée</strong></h2>
                <SelectGlassColor vehicle={ order.vehicle } opening={ order.job } onColorSelected={ this.handleColorChanged } />
            </div>
        }


        if (!complete) return <div>
            <h2>Véhicule</h2>
            { this.renderVehicleSummary() }
            <h2>Travaux</h2>
            { this.renderJobSummary() }  
            <h2><strong>Informations supplémentaires</strong></h2>
            <OrderContactEdit onSubmit={ this.handleContactSubmit } />
        </div>

        return this.renderFinalSummary()
    }

    renderVehicleSummary() {
        const { order, complete } = this.state

        return <ul className="list-group">
            <li className="list-group-item">{ order.vehicle.description }
                { complete ? null : <button className="btn btn-danger pull-right" onClick={ this.handleClearVehicleSelection }>Modifier</button> }
                <div className="clearfix"></div>
            </li>
        </ul>
    }

    renderJobSummary() {
        const { order, complete } = this.state
        return <ul className="list-group">
            <li className="list-group-item">
                { order.job.opening.description } 
                { order.job.side ? ` - ${ order.job.side.qualifier.value }` : '' }
                { order.job.position ? ` - ${ order.job.position.qualifier.value }` : '' }
                { order.job.color ? ` (${ order.job.color.name })` : '' }
                { complete ? null : <button className="btn btn-danger pull-right" onClick={ this.handleClearJob }>Modifier</button> }
                <div className="clearfix"></div>
            </li>
        </ul>
    }

    renderFinalSummary() {
        const { order } = this.state
        return <div>
            <div>
                <strong>
                    Votre demande a été enregistrée et sera traitée dès que possible. Vous recevrez une confirmation par courriel sous peu
                </strong>
            </div>
            <h2>Véhicule</h2>
            { this.renderVehicleSummary() }
            <h2>Travaux</h2>
            { this.renderJobSummary() }  
            <h2>Informations supplémentaires</h2>
            <table className="table table-responsive">
                <tbody>
                    <tr>
                        <td align="right"><strong>Type de travail</strong>&nbsp;</td>
                        <td>{ order.details.workType === 'repair' ? 'repair' : 'replace' }</td>
                    </tr>
                    <tr>
                        <td align="right"><strong>Nom</strong>&nbsp;</td>
                        <td>{ order.details.name }</td>
                    </tr>
                    <tr>
                        <td align="right"><strong>Courriel</strong>&nbsp;</td>
                        <td>{ order.details.email }</td>
                    </tr>
                    <tr>
                        <td align="right"><strong>Téléphone</strong>&nbsp;</td>
                        <td>{ order.details.phone }</td>
                    </tr>
                </tbody>
            </table>
        </div>
    }
    
}

Application.registerComponent(VehicleGlassOrder, 'vehicle.glass.order')