import React from 'react'
import { Application } from '../../../Application'

export class SelectQualifiers extends React.Component {
    constructor(props) {
        super(props)
        this.state = { qualifiers: null, repair: null, selectedQualifiers: [] }
    }

    async componentDidMount() {
        const job = this.props.opening

        const vehicle = this.props.vehicle.id
        const opening = job.opening.code
        const side = (job.side || {}).code
        const position = (job.position || {}).code
        const color = (job.color || {}).code

        const query = { vehicle, opening, side, position, color}

        const { qualifiers = []} = await Application.get({ path: '/glass-order/qualifiers', query})

        this.setState({ qualifiers })
    }

    toggleCharacteristic(qualifier) {
        let qualifiers = this.state.selectedQualifiers

        if (qualifiers.indexOf(qualifier) === -1)
            qualifiers.push(qualifier)
        else
            qualifiers = qualifiers.filter(q => q !== qualifier)

        this.setState({ selectedQualifiers: qualifiers })
    }

    handleSubmit = () => {
        this.props.onQualifiersSelected(this.state.selectedQualifiers, this.state.repair)
    }

    render() {
        if (!this.state.qualifiers)
            return <div className="waiting"></div>

        const characteristics = this.state.qualifiers.map((characteristic, index) => { 
            return <div className="checkbox" key={ index }>
                <label>
                    <input type="checkbox" onChange={ () => this.toggleCharacteristic(characteristic.value) } />
                    { characteristic.value }
                </label>
            </div>
        })

        return <><div className="row">
            <div className={ characteristics.length > 0 ? 'col-xs-12 col-sm-6' : 'col-xs-12' }>
                Est-ce que le verre peut être réparé?
                <br/>
                <label><input type="radio" name="repair" onClick={ () => this.setState({ repair: true }) } />&nbsp;Oui</label>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <label><input type="radio" name="repair" onClick={ () => this.setState({ repair: false }) }/>&nbsp;Non</label>
                
            </div>
            { characteristics.length > 0 && this.state.repair === false ? <div className="col-xs-12 col-sm-6">Spécifiez les caractéristiques du verre: { characteristics }</div> : null}
            
        </div>
        { this.state.repair !== null ? <div>
            <button className="btn btn-primary pull-right" onClick={ this.handleSubmit }>Accepter</button>
            <div className="clearfix"></div>
        </div> : null }</>
    }
}
