import React from 'react'
import { Application } from '../../../Application'
import { SelectOtherParts } from './select-other'


class PartPrice extends React.Component {
    constructor(props) {
        super(props)

        this.state = { price: this.props.price || '', editing: false }
    }

    handlePriceChange = (event) => {
        this.setState({ price: event.target.value })
    }

    handleAccept = (event) => {
        const { price } = this.state

        if (typeof price === 'number')  
            this.props.onPriceChange(price)
        else
            this.props.onPriceChange(Number.parseFloat(this.state.price))
    }

    render() {
        
        
        if (typeof this.props.price === 'number' && !this.state.editing) {
            const estimate = this.props.price && this.props.markup ? <span className="text-success">(+{ this.props.markup }% = { ((this.props.price * (100 + this.props.markup)) / 100).toFixed(2) }$)</span> : null
            return <div>
                <span className='text-success'>{ this.props.price.toFixed(2) }$</span>
                { estimate }
                &nbsp;
                <button className='btn btn-primary' onClick={ () => this.setState({ editing: true })}>Modifier</button>
            </div>
        }

        const floatValue = Number.parseFloat(this.state.price)
        const valid = typeof floatValue === 'number' && floatValue > 1 && !Number.isNaN(floatValue)
        const estimate = floatValue && this.props.markup ? <div className="text-success">+{ this.props.markup }% = { ((floatValue * (100 + this.props.markup)) / 100).toFixed(2) }$</div> : null

        const button = valid ? 
            <button className="btn btn-primary" type="button" onClick={ this.handleAccept }>Accepter</button> :
            (this.state.editing ? <button className="btn btn-primary" type="button">
                <i className="fa fa-close" />
            </button> : null)

        return <div>
            { this.props.msrp ? <div className="text-muted">~{ this.props.msrp.toFixed(2) }$</div> : null }
            <div className="input-group">
                <input type="text" className="form-control" value={ this.state.price } onChange={ this.handlePriceChange } />
                <span className="input-group-btn">
                    { button }
                </span>
            </div>
            { estimate }
        </div>
    }
}




export class SelectGlassParts extends React.Component {
    constructor(props) {
        super(props)

        this.state = { parts: null, selected: [], other: this.props.other || [] }
    }

    async componentDidMount() {

        if (!this.props.partConfiguration) {
            this.setState({ parts: [] })
            return
        }
        
        const result = await Application.get({ path: '/glass-order/parts', 
            query: { partConfiguration: this.props.partConfiguration }})

        const { parts } = result
        const { pricing = {}} = this.props

        for (const part of parts) {
            part.price = pricing[part.id] || null
        }

        this.setState({ parts })
    }

    toggle = (event) => {
        const part = event.target.dataset.part
        const checked = event.target.checked
        const partDefinition = this.state.parts.find(p => p.id === part)
        const hasPart = this.state.selected.find(p => p.id === part)

        if (checked && !hasPart) {
            const selected = this.state.selected
            selected.push({ id: part, quantity: 1, part: partDefinition })
            this.setState({ selected })
        }
 
        if (!checked && hasPart) {
            const selected = this.state.selected.filter(p => p.id !== part)
            this.setState({ selected })
        }
    }

    handleQuantityChange = (event) => {
        const part = event.target.dataset.part
        const parsedQuantity = Number.parseInt(event.target.value)
        const quantity = parsedQuantity && !Number.isNaN(parsedQuantity) ? parsedQuantity : 0

        if (part && quantity >= 0) {
            const selected = this.state.selected
            selected.find(p => p.id === part).quantity = quantity
            this.setState({ selected })
        }
    }

    handleAccept = (event) => {
        const { selected, parts, other } = this.state

        const hasMolding = selected.find(p => {
            const part = parts.find(p2 => p2.id === p.id)
            if (part && part.type.code === 'ML') return true
        })

        if (!hasMolding) {
            const process = confirm(`Vous n'avez pas spécifié de moulure, voulez-vous continuer sans l'ajouter?`)
            if (process)
                this.props.onPartsSelected(selected, other)
        } else {
            this.props.onPartsSelected(selected, other)
        }
    }

    handlePriceChange = (partId, price) => {
        const { parts } = this.state
        const part = parts.find(p => p.id === partId)

        if (part) part.price = price

        this.setState({ parts })
    }

    handleOtherPartsChanged = parts => {
        this.setState({ other: parts })   
    }

    render() {
        if (!this.state.parts)
            return <div className="waiting"></div>

        const { selected } = this.state

        const hasUnpricedParts = selected.find(p => p.part && typeof p.part.price !== 'number' || p.part.price <= 0 || Number.isNaN(p.part.price))
        
        
        const parts = this.state.parts.map((part, index) => {
            
            const option = part.options && part.options.length > 0 ? part.options[0] : null
            const qualifiers = (part.qualifiers && part.qualifiers.length > 0 ? part.qualifiers.map(q => q.value) : []).join(', ')
            const hasPart = selected.find(p => p.id === part.id)
            const msrp = option && option.pricing && option.pricing['Canada'] ? option.pricing['Canada'].price : null

            return <tr key={ index } className={ part.type.code === 'ML' ? 'active' : null }>
                <td><input type="checkbox" onChange={ this.toggle } data-part={ part.id } /></td>
                <td>{ hasPart ? <input data-part={ part.id } className="form-control" value={ hasPart.quantity } type="number" onChange={ this.handleQuantityChange }/> : null }</td>
                <td>
                    { `${ part.type.code }${ part.number.toString().padStart(6, '0') }` }<br/>
                    { part.type.name } { qualifiers.length > 0 ? `- ${ qualifiers }` : null }
                </td>
                <td>
                    { option ? option.manufacturer.name : 'N/A'}<br/>
                    { option ? option.number : 'N/A' }
                </td>
                <td align="right"> {
                    hasPart ? 
                    <PartPrice markup={ this.props.markup } msrp={ msrp } part={ part.id } price={ part.price } onPriceChange={ (price) => this.handlePriceChange(part.id, price) } />
                    : (part.price ? <div className="text-success"><strong>{ part.price.toFixed(2) }$</strong></div>: (msrp ? <div className="text-muted">~{ msrp.toFixed(2) }$</div> : null))
                }</td>
        </tr>})


        return <div>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th colSpan="2">Quantitée</th>
                        <th>Pièce</th>
                        <th>Fabricant</th>
                        <th align="right">Prix</th>
                    </tr>
                </thead>
                <tbody>
                    { parts }
                    <SelectOtherParts parts={ this.state.other } onChanged={ this.handleOtherPartsChanged }/>
                </tbody>
            </table>
            { hasUnpricedParts ? <div className="alert alert-danger">Complétez les prix des pièces pour continuer</div> : 
            <div>
                <button className="btn btn-primary pull-right" onClick={ this.handleAccept }>
                    <i className="fa fa-check"/>
                    &nbsp;
                    Accepter
                </button>
                <div className="clearfix"></div>
            </div> }
            <br/>
        </div>
    }
}


//

/*
class OtherPartEditor extends React.Component {
    constructor(props) {
        super(props)

        const { part = { quantity: 1, part: '', description: '', price: null }} = props
        
        const stateObject = { 
            editing: !props.part, 
            quantity: Number.isSafeInteger(part.quantity) ? part.quantity.toString() : '1',
            price: !part.price || Number.isNaN(part.price) ? '' : part.price.toString(),
            part: part.part, 
            description: part.description
        }

        stateObject.validation = this.validate(stateObject)

        this.state = stateObject
    }

    parse(state) {
        state = state || this.state

        const { quantity: quantityString = '', part = '', description = '', price: priceString = '' } = state
        
        const parsedQuantity = Number.parseInt(quantityString)
        const parsedPrice = Number.parseFloat(priceString)
        const quantity = !Number.isNaN(parsedQuantity) ? parsedQuantity : 1
        const price = priceString !== '' && !Number.isNaN(parsedPrice) ? parsedPrice : null

        return { quantity, part, description, price }
    }

    validate(state = null) {
        const { quantity = Number.NaN, part = '', price } = this.parse(state)
        const validation = {}

        if (!quantity || Number.isNaN(quantity) || quantity < 1) 
            validation.quantity = 'Quantité requise'

        if (price === null || Number.isNaN(price))
            validation.price = 'Prix requis'

        if (part.length < 3)
            validation.part = 'Numéro de pièce requis'
        
        return validation
    }

    handleChange = event => {
        const { name, value } = event.target
        const state = {}
        state[name] = value

        if (name === 'price' && value.length > 0) {
            const parsedFloat = Number.parseFloat(value)

            if (Number.isNaN(parsedFloat)) return
        }

        const validation = this.validate({ ... this.state, ... state})
        state.validation = validation

        this.setState(state)
        
    }

    renderInput(field, value, placeholder, type='text') {
        const validation = (this.state.validation || {})[field]

        return <>
                <input type={ type } name={ field } value={ value } onChange={ this.handleChange } 
                className="form-control" placeholder={ placeholder } />
                { validation ? <div className="text-danger">{ validation }</div>: null }
            </>

    }

    handleCancel = () => {

        if (this.props.onCancel) 
            this.props.onCancel()
        else
            this.setState({ editing: false })

    }

    render() {
        const { part, editing, validation,
            part: partString, 
            quantity: quantityString, 
            description: descriptionString, 
            price: priceString } = this.state

        if (!editing)
            return <tr>
                <td></td>
                <td>{ part.quantity }</td>
                <td>{ part.part }</td>
                <td>{ part.description }</td>
                <td align="right">{ part.price }</td>
            </tr>

        const hasErrors = Object.keys(validation).length !== 0

        const acceptButton = hasErrors ? null : 
            <button className="btn btn-success btn-xs"><i className="fa fa-check"/>&nbsp;Accepter</button>

        const cancelButton = <button className="btn btn-danger btn-xs"
            onClick={ this.handleCancel }><i className="fa fa-close"/>&nbsp;Annuler</button>
        
        return <tr className="active">
            <td colSpan="2">
                { this.renderInput('quantity', quantityString, 'Quantité', 'number') }
            </td>
            <td>
                { this.renderInput('part', partString, 'Numéro de pièce') }
            </td>
            <td>
                { this.renderInput('description', descriptionString, 'Description') }
            </td>
            <td align="right">
                { this.renderInput('price', priceString, 'Prix') }
                { acceptButton }&nbsp;{ cancelButton }
            </td>

        </tr> 
    }
}*/
