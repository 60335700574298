import React from 'react'
import { Application } from '../../Application'
import { GlassOrderList } from './order/list'
import { GlassOrderEdit } from './order/edit'
import { VehicleGlassMobile } from './mobile'


class VehicleGlassManager extends React.Component {
    constructor(props) {
        super(props)

        const parameters = new URLSearchParams(location.search)

        const view = parameters.has('view') ? parameters.get('view') : 'home'
        const order = parameters.has('order') ? Number.parseInt(parameters.get('order')) : null

        this.state = { view, order, services: null, summary: {} }
        this.pushState({ view, order }, false)
    }

    async componentDidMount() {
        const { services, summary } = await Application.get({ path: '/glass-order/home' })

        this.setState({ services, summary })
    }

    pushState(state, set = true) {
        const { view, order } = state
        
        const url = new URL(location)
        url.searchParams.set('view', view)
        if (order) 
            url.searchParams.set('order', order)
        else 
            url.searchParams.delete('order')
        
        const nextState = { view, order }
        window.history.pushState(nextState, 'Test', url.toString())
        window.history.replaceState(nextState, 'Test', url.toString())

        if (set) this.setState({ view, order })
    }

    handleCreateOrder = async () => {
        const { id } = await Application.get({ path: '/glass-order/order', query: { json: true }})

        this.handleOrderClicked(id)
    }

    handleOrderClicked = id => {
        this.pushState({ view: 'edit', order: id })
        
    }
    
    handleListClicked = () => {
        this.pushState({ view: 'list', order: null })
       
    }

    handleMobileClicked = () => {
        this.pushState({ view: 'mobile', order: null })
       
    }

    handleHomeClicked = () => {
        this.pushState({ view: 'home', order: null })
    }

    handleOrderClosed = () => {
        this.pushState({ view: this.state.view, order: null })
    }

    render() {
        const { view, order, services, summary } = this.state

        if (!services) 
            return <div className="waiting"></div>

        if (view === 'home') 
            return this.renderHome()
        
        if (view === 'edit' && order) 
            return <GlassOrderEdit order={ order } onOrderClosed={ this.handleListClicked } />

        if (view === 'mobile')
            return <VehicleGlassMobile onHomeClicked={ this.handleHomeClicked } order={ order } onOrderClosed={ this.handleOrderClosed } />
                
        return <GlassOrderList onOrderClicked={ this.handleOrderClicked } onHomeClicked={ this.handleHomeClicked } onOrderClosed={ this.handleListClicked } onCreateOrder={ this.handleCreateOrder } />
    }

    
    renderLinkButton(title, className, url, target) {
        return <div className={ `col-xs-12 col-sm-6 glass-${ className }` } onClick={ () => window.open(url, target) }>
            <div data-url={ url } data-test2="2"><span className="title">{ title }</span></div>
        </div>
    } 

    renderHome() {
        const { services, summary = {}} = this.state
        const { glass, mobile } = summary

        const orderTile = services.indexOf('vehicle-glass') !== -1 ?
            <div className="col-xs-12 col-sm-6 glass-list" onClick={ this.handleListClicked }>
                <div>
                    <span className="title">MES COMMANDES DE PARE-BRISES</span>
                    <span className="status">
                        { glass && glass.quote ? <div className="status"><strong>{ glass.quote }</strong> soumission(s) incomplète(s)</div> : (glass && glass.work ? <div>{ glass.work } travaux en cours</div> : null) }
                    </span>
                </div>
            </div>: null

        const mobileTile = services.indexOf('vehicle-glass-mobile') !== -1 ?
        <div className="col-xs-12 col-sm-6 glass-list" onClick={ this.handleMobileClicked }>
            <div>
                <span className="title">COMMANDES DE PARE-BRISES MOBILE</span>
                <span className="status">
                    { mobile && mobile.schedule ? <div className="status"><strong>{ mobile.schedule }</strong> demande(s) en attente</div> : (mobile && mobile.scheduled ? <div>{ mobile.scheduled } travaux en cours</div> : null) }
                </span>
            </div>
        </div>: null

        return <div>
            <h1>Commandes de pare-brises</h1>
            <div className="row glass-home">
                { orderTile }
                { mobileTile }
                { this.renderLinkButton('CONFIGURATION', 'configuration', Application.url({ path: '/configure/glass' }), '_self') }
                { this.renderLinkButton('PGW', 'pgw', 'https://www.buypgwautoglass.com/') }
                { this.renderLinkButton('PH VITRES D\'AUTO', 'ph', 'https://www.ph.ca/products/pare-brise-2/') }
                { this.renderLinkButton('VANFAX', 'vanfax', 'https://www.vanfax.com/fr/connexion') }
                { this.renderLinkButton('RÉCLAMATION D\'ASSURANCE', 'uniban', 'http://uniban.ca/fr-CA/Home/Index') }
                
            </div>
        </div>
    }
}

Application.registerComponent(VehicleGlassManager, 'vehicle.glass.home')