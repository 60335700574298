import React from 'react'
import { TextEdit } from '../../../editors/text'

export class PartsEditor extends React.Component {
    constructor(props) {
        super(props)

        const state = { identifier: '', quantity: '', price: '', editing: false }
        state.validation = this.validate(state)

        this.state = state

    }

    reset() {
        const state = { identifier: '', quantity: '', price: '', editing: false }
        state.validation = this.validate(state)

        this.setState(state)
    }

    extract(state = {}) {
        state = { ... this.state, ... state }
       
        const quantity = Number.parseInt(state.quantity || '')
        const price = Number.parseFloat(state.price || '')
        const identifier = state.identifier || ''

        return { quantity, identifier, price }
        
    }

    validate(state) {
        const part = this.extract(state)
        const validation = { errors: {}, warnings: {}}

        const { quantity, identifier, price } = part

        

        if (Number.isNaN(quantity) || quantity < 1)
            validation.errors.quantity = `Quantité requise`
        
        if (!identifier || identifier.length < 3) 
            validation.errors.identifier = `Identifiant requis`
        
        if (Number.isNaN(price) || price < 0)
            validation.errors.price = `Prix requis`
        
        return validation
        
    }
    
    handleChange(field, value) {
        const state = {}
        state[field] = value
        state.validation = this.validate(state)        

        this.setState(state)
    }

    handleDelete(part) {
        const parts = this.props.parts.filter(part2 => part2.identifier !== part.identifier)

        this.props.onChanged(parts)
    }

    handleEdit(part) {
        this.setState({ ... part, validation: this.validate(part), editing: true })
    }

    handleSave = () => {
        const part = this.extract()

        const parts = this.props.parts.filter(part2 => part2.identifier !== part.identifier)
        parts.push(part)

        this.reset()

        this.props.onChanged(parts)
    }

    render() { 
        
        const { identifier, quantity, price, validation, editing } = this.state
        const { errors } = validation

        const valid = Object.keys(errors).length === 0

        const items = (this.props.parts || []).map((part, index) => <tr key={ index }>
            <td>{ part.quantity }</td>
            <td>{ part.identifier }</td>
            <td align="right">{ part.price.toFixed(2) }$</td>
            <td align="right">
                <button className="btn btn-xs btn-danger" onClick={ () => this.handleDelete(part) }>
                    <i className="fa fa-close"></i>
                </button>
                &nbsp;
                <button className="btn btn-xs btn-primary" onClick={ () => this.handleEdit(part) }>
                    <i className="fa fa-pencil"></i>
                </button>
            </td>
        </tr>)

        return <div>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Quantité</th>
                        <th>Pièce</th>
                        <th className="text-right">Prix vendant</th>
                        <th className="text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { items.length > 0 ? items : <tr><td colSpan="4" className="text-info">Cette modification n'a pas encore de pièce</td></tr> }
                    { editing ? 
                    <tr>
                        <td><TextEdit value={ quantity } placeholder="Qte" error={ errors.quantity } 
                            onChange={ value => this.handleChange('quantity', value) } /></td>
                        <td><TextEdit value={ identifier } placeholder="Identifiant (numéro de pièce)" error={ errors.identifier }  
                            onChange={ value => this.handleChange('identifier', value) } /></td>
                        <td><TextEdit value={ price } placeholder="Prix vendant" error={ errors.price } 
                            onChange={ value => this.handleChange('price', value) }/></td>
                        <td>
                            <button className="btn btn-success btn-xs" onClick={ this.handleSave } disabled={ !valid }> 
                                <i className="fa fa-check"></i>
                            </button>
                        </td>
                    </tr> :
                    <tr className="info">
                        <td colSpan="4">
                            <button className="btn btn-info" style={{ display: 'block', width: '100%' }} onClick={ () => this.setState({ editing: true })}>Ajouter une pièce</button>
                        </td>
                    </tr> }
                </tbody>
            </table>
        </div>


    }
}
