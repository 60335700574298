import React from 'react'
import { Application } from '../../../Application'


function getStatusTitle(status) {
    switch (status) {
        case 'active':
            return 'Toutes les commandes actives'
        case 'quote': 
            return 'Soumissions incomplètes'
        case 'work': 
            return 'Travaux en cours'
        case 'complete':
            return 'Travaux complétés'
        case 'canceled': 
            return 'Travaux annulés'
        case 'schedule': 
            return 'En attente (mobile)'
        case 'scheduled': 
            return 'Travaux en cours (mobile)'
        default:
            return 'Unknown state'
        
    }
}


function SelectTask(props) {
    function renderOption(status) {
        if (status === '-') 
            return <li key='seperator' role='seperator' className='divider'></li>

        if (status === 'active') 
            return <li key={ status }><a href="#" onClick={ () => props.onChange(status)}>{ getStatusTitle(status) }</a></li>
        

        const count = props.summary[status]
        if (!count) return null

        return <li key={ status }><a href="#" onClick={ () => props.onChange(status)}>{ getStatusTitle(status) } <span className="badge pull-right">{ count }</span></a></li>
    }

    return <div className="btn-group">
        <button type="button" className="btn btn-primary" onClick={ () => props.onChange(props.status)}>{ getStatusTitle(props.status) }</button>
        <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span className="caret"></span>
            <span className="sr-only">Toggle Dropdown</span>
        </button>
        <ul className="dropdown-menu">
            { ['active', 'quote', 'work', 'schedule', 'scheduled', '-', 'complete', 'canceled'].map(status => renderOption(status)) }
        </ul>
    </div>
}

export class GlassOrderList extends React.Component {
    constructor(props) {
        super(props)

        this.state = { status: 'active', orders: null, summary: {} }
    }

    async refreshList(status) {
        status = status || this.state.status || 'active'

        const { orders, summary } = await Application.post({ path: '/glass-order/list', query: { status }})
        
        this.setState({ orders, summary, status })
    }

    async componentDidMount() {
        return this.refreshList()
    }

    renderStatus(status) {
        let title 
        let style
        switch (status) {
            case 'quote':
                style = 'info'
                title ='Soumission incomplète'
                break
            case 'work':
                style = 'success'
                title = 'Travaux en cours'
                break
            case 'schedule':
                style = 'info'
                title = 'Demande de rendez-vous mobile'
                break
            case 'scheduled':
                style = 'success'
                title = 'Travaux en cours (mobile)'
                break
            default:
                style = 'muted'
                title = status
                break
        }

        style = `text-${ style }`
        
        return <span className={ style }>{ title }</span>
    }

    handleStatusChange = async status => {
        await this.refreshList(status)
    }

    render() {
        if (!this.state.orders)
            return  <div>
                        <div className="waiting"></div>
                    </div>

        const { orders, status, summary = {}} = this.state
        
        const children = 
            orders.length === 0 ? 
            <tr><td colSpan={ status === 'active' ? 5 : 4 }><span className="text-success">Aucune commande disponible</span></td></tr> :
            
            orders.map(order => {
            const hasRejectedDates = order.status === 'quote' && (order.mobile && order.mobile.rejectedDates && order.mobile.rejectedDates.length > 0)
            const  { selectedDate } = order.mobile || {}
            const scheduledKey = selectedDate ? `${ selectedDate.datePart } ${ selectedDate.timePart }` : null

            return <tr key={ order.id }>
                    { status !== 'active' ? null: <td>
                        { this.renderStatus(order.status) }
                        <br/>
                        { hasRejectedDates ? <span className="text-danger">
                            <i className="fa fa-warning" />
                            Dates rejetées par le mobile
                            </span> : null }
                    </td> }
                    <td>{ scheduledKey ? 
                        <strong className="text-success">{ Application.date(scheduledKey) }</strong> :
                        <span>Créé le { order.date }</span> }</td>
                    <td>
                        <strong>{ order.details ? order.details.name : 'N/A' }</strong><br/>
                        { order.details ? order.details.phone : 'N/A' }<br/>
                    </td>
                    <td>
                        <span className="label label-info">{ order && order.job ? (order.job.repair ? 'Réparer' : 'Remplacer') : 'N/A' }</span>
                        &nbsp;
                        <strong>{ order.vehicle ? order.vehicle.description : 'N/A' }</strong>
                        
                        
                        <br/>
                        
                        { order.job ? `${ order.job.opening.description } ${ order.job.position ? order.job.position.qualifier.value : ''} ${ order.job.side ? order.job.side.qualifier.value : ''} ` : null}
                        { order.job && order.job.color ? order.job.color.name : 'N/A'}
                    </td>
                    <td>
                        <a onClick={ () => this.props.onOrderClicked(order.id) }>
                            <i className="fa fa-search fa-2x"/>
                        </a>
                    </td>
                </tr>  
            }  
        )
      

        return  <div>
            <h2>Estimation de pare-brises</h2>
            <SelectTask summary={ summary } status={ status } onChange={ this.handleStatusChange }/>
            <div className="pull-right">
                <a className="btn btn-primary" onClick={ this.props.onHomeClicked }>
                    <i className="fa fa-home"></i>
                    &nbsp;
                    Tableau de bord
                </a>
                &nbsp;
                <a className="btn btn-primary" onClick={ this.props.onCreateOrder }>
                    <i className="fa fa-plus"></i>
                    &nbsp;
                    Créer
                </a>
            </div>
            <div className="clearfix"></div>
            <hr />
            <table className="table table-responsive">
            <thead>
                <tr>
                    { status !== 'active' ? null : <th>Statut</th> }
                    <th>Date</th>
                    <th>Contact</th>
                    <th>Travaux</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                { children }
            </tbody>
        </table>
        </div>

        
    }
}
