import React from 'react'

export class DropdownEdit extends React.Component {
    constructor(props) {
        super(props)
    }

    setValue(value) {
        this.props.onChange(value)
    }

    handleInputChange = event => {
        const { value } = event.target

        this.setValue(value)
    }

    render() {
        const { options = [], error, warning, label = '' } = this.props

        const input = <input type="text" value={ this.props.value } className="form-control" aria-label="..." onChange={ this.handleInputChange } />

        const errorMessage = error ? <div className="text-danger">{ error }</div> : null
        const warningMessage = warning ? <div className="text-warning">{ warning }</div> : null

        if (options.length === 0) 
            return <div className="form-group">
                <label>{ label }</label>
                { input }
                { errorMessage }
                { warningMessage }
            </div>

        const listItems = options.map(option => <li key={ option }><a onClick={ () => this.setValue(option) }>{ option }</a></li>)

        return <div>
            <label>{ label }</label>
            <div className="input-group">
                { input }
                <div className="input-group-btn">
                    <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right">{ listItems }</ul>
                </div>
            </div>
            { errorMessage }
            { warningMessage }
        </div>
    }
}