import React from 'react'
import { Application } from '../Application'
const map = '0123456789X', weights = '8765432X098765432'

// source: https://stackoverflow.com/questions/26407015/javascript-jquery-vin-validator/26408196
function validateVin(vin) {

    // source: https://en.wikipedia.org/wiki/Vehicle_identification_number#Example_Code
    // ---------------------------------------------------------------------------------
    function transliterate(character) {
        return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(character) % 10
    }

    function getCheckDigit(vin) {
        let sum = 0
        for (let i = 0; i < 17; ++i)
            sum += transliterate(vin[i]) * map.indexOf(weights[i])
        
        return map[sum % 11]
    }

    if (vin.length !== 17) return false
    
    return getCheckDigit(vin) === vin[8]
}


export class FindVehiclePanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = { type: this.props.type || 'model', model: '', vin: '', year: (new Date().getFullYear()), vehicles: null }
    }

    handleChange = event => {
        if (this.state.type === 'model')
            this.setState({ model: event.target.value })
        else
            this.setState({ vin: event.target.value })
    }

    validate() {
        const { type, model, vin } = this.state

        if (type === 'model') {
            if (!model)
                return { valid: false, message: <div className="text-info">Spécifiez le modèle du véhicule pour choisir parmi une liste</div> }
            if (model.length < 3)
                return { valid: false, message: <div className="text-danger">3 caractères minimum</div> }
            
            return { valid: true }
        } 
        
        if (!vin)
            return { valid: false, message: <div className="text-info">Écrivez les 17 caractère du NIV du véhicule</div> }

        if (vin.trim().length !== 17)
            return { valid: false, message: <div className="text-danger">Le NIV doit être 17 caractères</div> }
        else if (!validateVin(vin))
            return { valid: false, message: <div className="text-danger">Le NIV semble contenir une erreur</div> }
    
        return { valid: true }
    }

    handleClick = async event => {
        const { valid } = this.validate()

        if (!valid)
            return event.preventDefault()

        const { type, model, vin, year = 2020 } = this.state

        const query = type === 'model' ? { model, year } : { vin }

        const result = await Application.get({ path: '/vehicle/lookup', query })

        const vehicles = result.vehicles
        
        vehicles.sort((v1, v2) => v1.Name < v2.Name ? -1 : 1)
        this.setState({ vehicles })

    }

    handleVehicleSelection = (event) => {
        const id = Number.parseInt(event.target.dataset.id)
        const { description } = event.target.dataset
                
        this.props.onVehicleSelected(id, description, this.state.vin)
    }

    handleResetSearch = (event) => {
        this.setState({ vehicles: null })   
    }

    renderSearchForm() {
        const { 
            valid, 
            message : validation = <div className="text-success">Cliquez sur la loupe pour effectuer la recherche</div> 
        } = this.validate()

        const { type, model, vin, year, vehicles } = this.state

        const years = new Array()
        for (let possibleYear = (new Date().getFullYear() + 1); possibleYear > 1970; possibleYear--)
            years.push(<option key={ possibleYear }>{ possibleYear }</option>)
 
        const form = 
            type === 'vin' ? 
        (<div className="input-group">
            <input type="text" className="form-control" onChange={ this.handleChange } placeholder="Recherche par NIV" value={ vin } />
            <span className="input-group-btn">
                <button className="btn btn-primary" type="submit" onClick={ this.handleClick } disabled={ !valid }>
                    <i className="fa fa-search"/>
                </button>
            </span>
        </div>) : 
        (<div className="row">
            <div className="col-xs-12 col-sm-2">
                <label style={{ width: '100%' }} htmlFor="year">
                    Année
                    <select style={{ width: '100%' }} id="year" className="form-control" value={ year } onChange={ event => this.setState({ year: event.target.value }) }>
                            { years }
                    </select>
                </label>
            </div>
            <div className="col-xs-12 col-sm-10">
                <label htmlFor="model">
                    Modèle
                    <div className="input-group">
                        <input type="text" className="form-control" onChange={ this.handleChange }
                            placeholder="Recherche par modèle"
                            value={ type === 'model' ? model : vin } />

                        <span className="input-group-btn">
                            <button className="btn btn-primary" type="submit" onClick={ this.handleClick } disabled={ !valid }>
                                <i className="fa fa-search"/>
                            </button>
                        </span>
                    </div>
                </label>
            </div>
        </div>)
        
        return <div>
            <h4>Recherche par</h4>
            <ul className="nav nav-pills">
                <li className={ this.state.type === 'model' ? 'active disabled' : null }>
                    <a onClick={ () => this.setState({ type: 'model' })}>Modèle</a>
                </li>
                <li className={ this.state.type === 'vin' ? 'active disabled' : null }>
                    <a onClick={ () => this.setState({ type: 'vin' })}>NIV</a>
                </li>
            </ul>
            <hr/>
            <form onSubmit={ event => event.preventDefault() }>{ form }</form>
            { vehicles !== null && vehicles.length === 0 ? <div className="alert alert-info">Aucun véhicule trouvé, essayez une différente recherche</div> : validation }
        </div>

    }

    render() {
        const { type, model, vin, year, vehicles } = this.state

        if (vehicles === null || vehicles.length === 0) 
            return this.renderSearchForm() 

        const options = []
        for (const vehicle of vehicles) {
            const name = `${ vehicle.Name }${ vehicle.BodyStyle ? ` (${ vehicle.BodyStyle})` : '' }`
            options.push(<li key={ vehicle.Id } className="list-group-item">
                <span>{ name }</span>
                <a className="btn btn-success pull-right" onClick={ this.handleVehicleSelection } data-id={ vehicle.Id } data-description={ name }>Choisir</a>
                <div className="clearfix"></div>
            </li>)
        }
        
        return <div>
            
            <ul className="list-group">{ options }</ul>
            <div className="text-right">
                <a onClick={ this.handleResetSearch }>Le véhicule ne figure pas dans la liste, je veux faire une autre recherche</a>
            </div>
        </div>
    }

    
}
