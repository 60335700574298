import React from 'react'
import { DropdownEdit } from '../../../editors/dropdown'
import { DateEdit } from '../../../editors/date'


const inputStyle = 'col-xs-12 col-sm-6 col-md-4 col-lg-3'

export class ContactInformationForm extends React.Component {
    constructor(props) {
        super(props)

        const { name = '', vin = '', phone = '', email = '', insurer = '', policy = '', expiration = '', license = '', breakLocation = '', breakType = '', breakTime = '', insured = true, existing = false } = props.details

        this.state = { name, vin, insurer, expiration, phone, email, policy, license, insured, breakLocation, breakType, breakTime, existing, validation: {}, fixedVin: vin && vin.length === 17 }
   
    }

    componentDidMount() {
        const validation = this.validate()
        this.setState({ validation })
    }

    setDetail(field, value) {
        const state = { }
        state[field] = value

        state.validation = this.validate(state)

        this.setState(state)
    }

    handleSaveButton = () => {
        const validation = this.state.validation

        if (!validation || Object.keys(validation).length !== 0)
            return

        const details = { ... this.state }

        this.props.onSaved(details)
    }

    render() {
        const insuranceInformation = this.state.insured ? this.renderInsuranceInformation() : null
        const contactInformation = this.state.existing ? null : this.renderContactInformation()

        //const { validation = {}} = this.state

        const saveButton = this.state.validation && Object.keys(this.state.validation).length === 0 ? 
            <div className="col-xs-12">
                <hr/>
                <button className="pull-right btn btn-primary" onClick={ this.handleSaveButton }>Enregistrer</button>
                <div className="clearfix"></div>
            </div> : null

        return <div className="row">
            
            <div className="col-xs-12 col-md-6">
                <h3>Est-ce que les travaux sont assurés?</h3>
                <label className="radio-inline">
                    <input type="radio" name="insured" value="yes" checked={ this.state.insured } onChange={ this.handleChargeTypeChanged } />
                    Oui
                </label>
                <label className="radio-inline">
                    <input type="radio" name="insured" value="no" checked={ !this.state.insured } onChange={ this.handleChargeTypeChanged }/>
                    Non
                </label>
            </div>
            <div className="col-xs-12 col-md-6">
                <h3>Est-ce que les information de contact du client sont déjà inscrits à la caisse?</h3>
                <label className="radio-inline">
                    <input type="radio" name="existingClient" value="yes" checked={ this.state.existing } onChange={ this.handleExistingChanged } />
                    Oui
                </label>
                <label className="radio-inline">
                    <input type="radio" name="existingClient" value="no" checked={ !this.state.existing } onChange={ this.handleExistingChanged } />
                    Non
                </label>
            </div>
            <div className="col-xs-12">
                <hr/>
            </div>
            { this.renderEditor('name', 'Nom', this.state.name) }
            { insuranceInformation }
            { contactInformation }
            { saveButton }
            
        </div>
    }

    validate(override) {
        const state = { ... this.state, ... override}
        
        const { name, insured, vin, insurer, policy, expiration, license, breakType, breakLocation, breakTime } = state
        const validation = {}
        
        if (!name || name.length < 3) validation.name = 'Vous devez spécifier un nom'
        if (insured && (!vin || vin.length !== 17)) validation.vin = 'Le NIV est requis pour un travail assuré'
        if (insured && !expiration) validation.expiration ='Champs obligatoire'
        if (insured && !policy) validation.policy = 'Champs obligatoire'
        if (insured && !insurer) validation.insurer = 'Champs obligatoire'
        if (insured && !license) validation.license = 'Champs obligatoire'
        if (insured && !breakLocation) validation.breakLocation = 'Champs obligatoire'
        if (insured && !breakType) validation.breakType = 'Champs obligatoire'
        if (insured && !breakTime) validation.breakTime = 'Champs obligatoire'

        return validation
    }

    renderEditor(field, title, value, readOnly = false) {
        const { validation } = this.state


        return <div className={ inputStyle }>
            <div className="form-group">
                <label htmlFor={ field }>{ title }</label>
                <input type="text" className="form-control" name={ field }
                    onChange={ event => this.setDetail(field, event.target.value) }
                    value={ value } readOnly = { readOnly } />
            
                { validation[field] ? <div className="text-danger">{ validation[field] }</div> : null}
            </div>
        </div>
    }
    

    renderInsuranceInformation() {
        const { vin, insurer, policy, expiration, fixedVin, license, breakLocation, breakType, breakTime, validation } = this.state

        const breakLocations = [ 'Haut Droit (Upper Right)', 'Centre Droit (Middle Right)', 'Bas Droit (Lower Right)', 
                                 'Haut Centre (Upper Center)', 'Centre (Middle Center)', 'Bas Centre (Lower Center)', 
                                'Haut Gauche (Upper Left)', 'Centre Gauche (Middle Left)', 'Bas Gauche (Lower Left)']

        const breakTypes = [ `Bull's eye`, 'Combination', 'Crack (6" ou moins)', 'Crack (plus de 6")', 'Star']

        return <>
            { this.renderEditor('vin', 'VIN', vin, fixedVin) }
            { this.renderEditor('license', '# Permis de conduire', license) }
            { this.renderEditor('insurer', 'Assureur', insurer) }
            { this.renderEditor('policy', 'Numéro de police', policy) }
            { this.renderEditor('expiration', 'Date d\'expiration', expiration) }
            <div className={ inputStyle }>
                <DropdownEdit label="Endroit du bris" value={ breakLocation } options={ breakLocations } 
                    error={ validation['breakLocation'] }
                    onChange={ value => this.setDetail('breakLocation', value) } />
            </div>
            <div className={ inputStyle }>
                <DropdownEdit label="Type de bris" value={ breakType } options={ breakTypes } 
                    error={ validation['breakType'] }
                    onChange={ value => this.setDetail('breakType', value) } />
            </div>
            <div className={ inputStyle }>
                <DateEdit label="Date du bris" value={ breakTime } error={ validation['breakTime'] }
                    onChange={ value => this.setDetail('breakTime', value) }/>
            </div>
        </>
    }

    renderContactInformation() {
        const { email, phone } = this.state

        return <>
            { this.renderEditor('email', 'Courriel', email) }
            { this.renderEditor('phone', 'Téléphone', phone) }
        </>

    }

    handleChargeTypeChanged = (event) => {
        const insured = event.target.value === 'yes'

        const validation = this.validate()
        this.setState({ insured, validation })
        
    }
    
    handleExistingChanged = (event) => {
        const validation = this.validate()

        this.setState({ validation, existing: event.target.value === 'yes' })
    }

    /*handleSubmit = () => {
    }*/

}