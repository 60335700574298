import React from 'react'
import { Application } from '../../../Application'
import { FindVehiclePanel } from '../../find'
import { SelectOpening } from './select-opening'
import { SelectGlassColor } from './select-color'
import { SelectQualifiers } from './select-qualifiers'
import { SelectGlass } from './select-glass'
import { SelectGlassParts } from './select-parts'
import { SelectMobile } from './select-mobile'
import { ContactInformationForm } from './contact-info'

export class GlassOrderQuote extends React.Component {
    constructor(props) {
        super(props)

        this.state = { order: props.order, configuration: props.configuration || null }
   
    }

    async saveOrder(order) {
        const serializedOrder = JSON.stringify(order)

        const { success } = await Application.post({ path: '/glass-order/list', data: { order: this.props.id, data: serializedOrder }})
        
        if (!success) 
            throw new Error(`Failed saving order ${ serializedOrder }`)

    }

    async changeStatus(status) {
        await Application.get({ path: '/glass-order/status', query: { order: this.props.id, status }})
        this.props.onClosed()
    }

    handleClearVehicleSelection = async (event) => {
        const { order } = this.state
        order.vehicle = null
        order.job = null
        order.selected = null
        order.parts = null

        await this.saveOrder(order)

        this.setState({ order })
    }

    handleClearJob = async () => {
        const { order } = this.state
        
        order.job = null
        order.selected = null
        order.parts = null

        await this.saveOrder(order)

        this.setState({ order })
    }


    handleGlassSelection = async (item, key, provider, price) => {
        const { order } = this.state
        order.selected = item
        order.parts = null
        if (!order.pricing) order.pricing = {}
        order.pricing[key] = { provider, price }

        await this.saveOrder(order)
        this.setState({ order })
        
    }

    
    handleComplete = () => {
        const { order } = this.state

        if (confirm('Accepter cette commande?'))
            this.changeStatus(order.mobile && !order.mobile.local ? 'schedule' : 'work')    
    }

    handleCancel = () => {
        if (confirm('Voulez-vous annuler cette commande?'))
            this.changeStatus('canceled')
    }

    handleVehicleSelected = async (id, description, vin = null) => {
        const { order } = this.state

        if (id && description)  {
            order.vehicle = { id, description }
        } else {
            order.vehicle = null
        }

        if (!order.details) order.details = {}

        order.details.vin = vin
        order.selected = null
        order.parts = null
        
        await this.saveOrder(order)

        this.setState({ order })
    }

    handleOpeningSelection = async (openingSelection) => {
        const { order } = this.state

        
        order.job = openingSelection

        const vehicle = order.vehicle.id
        const opening = openingSelection.opening.code
        const side = (openingSelection.side || {}).code
        const position = (openingSelection.position || {}).code

        const query = { vehicle, opening, side, position }
        
        const { colors = []} = await Application.get({ path: '/glass-order/colors', query})

        if (colors.length === 0) 
            throw new Error('No colors available')

        if (colors.length === 1) 
            order.job.color = colors[0]

        await this.saveOrder(order)

        this.setState({ order })
    }

    handleColorChanged = async (color) => {
        const { order } = this.state
        
        order.job.color = color
        order.selected = null
        order.parts = null


        await this.saveOrder(order)

        this.setState({ order })
    }

    handleQualifiersSelection = async (qualifiers, repair) => {
        
        const { order } = this.state
        
        order.job.qualifiers = qualifiers
        order.job.repair = repair
        order.selected = null
        order.parts = null

        await this.saveOrder(order)

        this.setState({ order })
    }

    clearQualifiers = async () => {
        const { order } = this.state
        
        order.job.qualifiers = null
        order.job.repair = null
        order.selected = null
        order.parts = null

        await this.saveOrder(order)

        this.setState({ order })
    }

    handlePartSelection = async (selection, other) => {
        const { order } = this.state

        order.parts = selection
        order.other = other

        if (!order.pricing) order.pricing = {}

        for (const part of order.parts) {
            const id = part.id
            const price = part.part.price
            order.pricing[id] = price    
        }

        await this.saveOrder(order)

        this.setState({ order })
    }

    clearParts = async () => {
        const { order } = this.state

        order.parts = null

        await this.saveOrder(order)

        this.setState({ order })
    }

    handleContactInformationSaved = async details => {
        const { order } = this.state

        const merged = { ... order.details, ... details, modify: false }

        order.details = merged

        await this.saveOrder(order)

        this.setState({ order })
    }

    handleMobileChanged = async mobile => {
        const { order } = this.state
        order.mobile = mobile
        await this.saveOrder(order)

        this.setState({ order })
    }

    handleMobileChange = async () => {
        const { order } = this.state
        const { mobile = {}} = order
        mobile.change = true
        await this.saveOrder(order)

        this.setState({ order })
    }

    render() {
        if (!this.state.order) return <div className="waiting"></div>

        const { order, configuration } = this.state
        
        const summary = []

        if (!order.vehicle) 
            return <div>
                <h2><strong>Spécifiez le véhicule</strong></h2>
                <div className="text-muted">Vous pouvez chercher par modèle, mais le NIV sera nécessaire lors d'une étape ultérieure s'il n'est pas spécifié ici</div>
                <FindVehiclePanel onVehicleSelected={ this.handleVehicleSelected } />
            </div>

        summary.push(this.renderVehicleSummary())

        if (!order.job)
            return <div>
                { summary }
                <h2><strong>Spécifiez les travaux souhaités</strong></h2>
                <SelectOpening vehicle={ order.vehicle } onOpeningSelected={ this.handleOpeningSelection } />
            </div>
        summary.push(this.renderJobSummary())

        if (!order.job.color) 
            return <div>
                {  summary }
                <h2><strong>Spécifiez la couleur désirée</strong></h2>
                <SelectGlassColor vehicle={ order.vehicle } opening={ order.job } onColorSelected={ this.handleColorChanged } />
            </div>

        if (!order.job.qualifiers)
            return <div>
            { summary }  
            <h2>Sélectionnez les caractéristiques observables</h2>
            <SelectQualifiers vehicle={ order.vehicle } opening={ order.job } qualifiers={ order.job.qualifiers || [] } repair={ order.job.repair } onQualifiersSelected={ this.handleQualifiersSelection } />
        </div>

        summary.push(this.renderQualifiersSummary())

        if (order.job.repair !== true) {
    
            if (!order.selected) 
                return <div>
                    { summary }
                    <h2>Sélectionnez le verre utilisé</h2>
                    <SelectGlass markup={ this.state.configuration.markup || 66.7 } 
                        pricing={ order.pricing || {}} 
                        vehicle={ order.vehicle } opening={ order.job } 
                        qualifiers={ order.job.qualifiers || [] } 
                        onGlassSelected={ this.handleGlassSelection } />
                </div>
            summary.push(this.renderGlassSummary())

            if (!order.parts)
                return <div>
                    { summary }
                    <h2><strong>Sélectionnez les accessoires nécessaires</strong></h2>
                    <div className="text-muted">N.B. Vous devez choisir au moins une moulure (MLXXXXXX)</div>
                    <SelectGlassParts 
                         markup={ this.state.configuration.markup || 66.7 } 
                        partConfiguration={ order.selected.configuration }  
                        pricing={ order.pricing || {}}
                        other={ order.other || [] }
                        onPartsSelected={ this.handlePartSelection }  />
                </div>
            summary.push(this.renderPartsSummary())
            summary.push(this.renderEstimate())

        }

        if (!order.details || !order.details.name || order.details.modify)
            return <div>
                { summary }
                <h2>Information supplémentaire</h2>
                <ContactInformationForm details={ order.details } onSaved={ this.handleContactInformationSaved } />
                
            </div>

        summary.push(this.renderSummary())
        
        if (configuration.mobile && configuration.mobile.length > 0) {
            const hasDates = order.mobile && order.mobile.dates && order.mobile.dates.length > 1
            const { local = false, change = false } = order.mobile || {}
            

            if (change || (!hasDates && !local))
                return <div>
                    { summary }
                    <h2>Rendez-vous mobile</h2>
                    <SelectMobile order={ order } local={ configuration.local } onChange={ this.handleMobileChanged }/>
                </div>

            
            summary.push(this.renderMobileSummary())
        }


        return <div>
            { summary }
            { this.renderComplete() }
        </div>
 
    }
    
    changeDetails = () => {
        const { order } = this.state
        order.details.modify = true
        this.setState({ order })
    }
    

    renderGlassSummary() {
        const { order, configuration } = this.state
        const item = order.selected 
        

        const availability = item.pricing && item.pricing.availability && item.pricing.availability.name ? <div className='text-muted'>Disponible</div>: null
        const key = `${ item.prefix.code }${ item.number.toString().padStart(6, '0') }${ item.color }${ item.attachment ? 'Y': 'N'}`


        const pricing = order.pricing[key]


        const msrp = item.pricing && item.pricing.price > 0 ? <div className="text-muted">~ { item.pricing.price.toFixed(2) }$</div> : null

        const isANumber = pricing && typeof pricing.price === 'number'
        const estimate = isANumber && configuration.markup ? <div className="text-success">+{ configuration.markup }% = { ((pricing.price * (100 + configuration.markup)) / 100).toFixed(2) }$</div> : null

        const pricingLabel = isANumber ? <div className="text-success">{ pricing.provider }: { pricing.price.toFixed(2) }$</div> : msrp

        return <div key="glassSummary">
            <h2>Verre sélectionné</h2>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <td>
                            Numéro de pièce
                        </td>
                        <td>
                            Charactéristiques
                        </td>
                        <td>Prix et fournisseur</td>
                        <td>&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                    <tr className={ item.missingQualifiers && item.missingQualifiers.length > 0 ? 'danger' : 'active' }>
                        <td>
                            <strong>{ key }</strong>
                            { availability }
                        </td>
                        <td>
                            { item.qualifiers && item.qualifiers.length > 0 ? <strong>{ item.qualifiers.map(q => q.value).join(', ') }</strong> : null }
                            { item.missingQualifiers && item.missingQualifiers.length > 0 ? <div><strong className="text-danger">Caractéristiques manquantes: { item.missingQualifiers.join(', ') }</strong></div> : null }
                        </td>
                        <td>
                            { pricingLabel }
                            { estimate }
                           
                        </td>
                        <td align="right">
                            <button className="btn btn-primary" onClick={ () => this.handleGlassSelection(null) }>Modifier</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    }


    renderPartPrice({ price, msrp }) {
        return price ? 
            <div className="text-succes">{ price.toFixed(2) }$</div>:
            (msrp ? `~${ msrp.toFixed(2) }$` : null)
        
    }

    renderPartsSummary() {
        const { order } = this.state
        const { parts = [], other = [] } = order
        const { markup } = this.state.configuration

        const renderEstimate = price => price && markup ? <div className="text-success">+{ markup }% = { ((price * (100 + markup)) / 100).toFixed(2) }$</div> : null

        let partElements = parts.map((selectedPart, index) => {
            const part = selectedPart.part
            
            if (!part) return null

            const option = part.options && part.options.length > 0 ? part.options[0] : null

            const price = this.renderPartPrice({ 
                price: part.price, 
                msrp: (option && option.pricing && option.pricing['Canada']) ? option.pricing['Canada'] : null
            })
                        
            const qualifiers = (part.qualifiers && part.qualifiers.length > 0 ? part.qualifiers.map(q => q.value) : []).join(', ')
                        
            const estimate = renderEstimate(part.price)
            
            return <tr key={ index }>
                <td>{ selectedPart.quantity }</td>
                <td>
                    { `${ part.type ? part.type.code : 'XX' }${ part.number ? part.number.toString().padStart(6, '0') : 'N/A' }` }<br/>
                    { part.type ? part.type.name : 'Part' } { qualifiers.length > 0 ? `- ${ qualifiers }` : null }
                </td>
                <td>
                    { option ? option.manufacturer.name : 'N/A'}<br/>
                    { option ? option.number : 'N/A' }
                </td>
                <td>{ price }{ estimate }</td>
        </tr>})

        if (other.length > 0) {
            partElements = partElements.concat(other.map((part, index) => {
                return <tr key={ `other${ index }` }>
                    <td>{ part.quantity }</td>
                    <td>
                        { part.part }
                    </td>
                    <td>
                        { part.description }
                    </td>
                    <td>{ this.renderPartPrice({ price: part.price }) }{ renderEstimate(part.price) }</td>
                </tr>
            }))
        }

        if (partElements.length === 0) partElements=<tr><td colSpan="3">Aucune pièce sélectionnée</td></tr>

        return <div key="partSummary">
            <h2>Accessoires sélectionnés</h2>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Quantitée</th>
                        <th>Pièce et type</th>
                        <th>Fabricant / Description</th>
                        <th>Prix</th>
                    </tr>
                </thead>
                <tbody>
                    { partElements }
                </tbody>
            </table>
            <button className="btn btn-primary pull-right" onClick={ this.clearParts }>Modifier</button>
            <div className="clearfix"></div>
        </div>
    }
    
    renderSummary() {
        const { order } = this.state
        const { details = {}} = order

        const contactInformation = details.existing ? <tr>
            <td colSpan="2"><strong>Information à la caisse</strong></td>
        </tr>:<>
            <tr>
                <td align="right"><strong>Courriel</strong>&nbsp;</td>
                <td>{ details.email || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Téléphone</strong>&nbsp;</td>
                <td>{ details.phone || 'N/A' }</td>
            </tr>
        </>

        const insuranceInformation = details.insured ? <>
            <tr>
                <td align="right"><strong>NIV</strong>&nbsp;</td>
                <td>{ details.vin || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong># Permis de conduire</strong>&nbsp;</td>
                <td>{ details.license || 'N/A' }</td>
            </tr>
             <tr>
                <td align="right"><strong>Assureur</strong>&nbsp;</td>
                <td>{ details.insurer || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Numéro de police</strong>&nbsp;</td>
                <td>{ details.policy || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Expiration</strong>&nbsp;</td>
                <td>{ details.expiration || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Endroit du bris</strong>&nbsp;</td>
                <td>{ details.breakLocation || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Type de bris</strong>&nbsp;</td>
                <td>{ details.breakType || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Date du bris</strong>&nbsp;</td>
                <td>{ details.breakTime || 'N/A' }</td>
            </tr>
        </> : ( details.insured === false ? <tr><td colSpan="2"><strong>Le travail n'est pas assuré</strong></td></tr> : null)
        
        return <div key="summary">
            <h2>Information supplémentaire</h2>
            <table className="table table-responsive">
                <tbody>
                    <tr>
                        <td align="right"><strong>Nom</strong>&nbsp;</td>
                        <td>{ details.name || 'N/A' }</td>
                    </tr>
                    { contactInformation }
                    { insuranceInformation }
                </tbody>
            </table>
            <button className="btn btn-primary pull-right" onClick={ () => this.changeDetails() }>Modifier</button>
            <div className="clearfix"></div>
            <hr/>
            
        </div>

    }

    renderVehicleSummary() {
        const { order } = this.state

        return <div key="vehicleSummary">
            <h2>Véhicule</h2>
            <ul className="list-group">
                <li className="list-group-item">{ order.vehicle.description }
                    <button className="btn btn-primary pull-right" onClick={ this.handleClearVehicleSelection }>Modifier</button>
                    <div className="clearfix"></div>
                </li>
            </ul>
        </div>
    }

    renderJobSummary() {
        const { order } = this.state
        return <div key="jobSummary">                
            <h2>Travaux</h2>
            <ul className="list-group">
                <li className="list-group-item">
                    { order.job.opening.description } 
                    { order.job.side ? ` - ${ order.job.side.qualifier.value }` : '' }
                    { order.job.position ? ` - ${ order.job.position.qualifier.value }` : '' }
                    { order.job.color ? ` (${ order.job.color.name })` : '' }
                    <button className="btn btn-primary pull-right" onClick={ this.handleClearJob }>Modifier</button>
                    <div className="clearfix"></div>
                </li>
            </ul>
        </div>
    }

    renderQualifiersSummary() {
        const { order } = this.state

        return <div key="qualifierSummary">
            <h2>Caractéristiques</h2>
            <ul className="list-group">
                <li className="list-group-item">
                    { order.job.repair === true ? 
                        `Le verre peut être réparé` : 
                        (order.job.qualifiers.length > 0 ? order.job.qualifiers.join(', ') : 'Le verre n\'a pas de caractéristique visible') }
                    
                    <button className="btn btn-primary pull-right" onClick={ this.clearQualifiers }>Modifier</button>
                    <div className="clearfix"></div>
                </li>
            </ul>
        </div>
    }

    renderComplete() {
        const { configuration, order } = this.state
        
        return <div>
            <hr/>
            <div className="alert alert-info">
                <strong>Vous pouvez maintenant soumettre cette commande</strong>
                <br/>
                { order.mobile && !order.mobile.local ? 
                    <span>Si vous acceptez cette commande, elle sera envoyé au mobile. Si vous annulez la commande, elle sera retirée de la liste des commandes actives</span> : 
                    <span>Si vous acceptez cette commande, son statut sera changé en attendant les travaux. Si vous annulez la commande, elle sera retirée de la liste des commandes actives</span>
                }
                <hr/>
                <span className="pull-right">
                    <button className="btn btn-danger" onClick={ this.handleCancel }>
                        <i className="fa fa-close"></i>
                        &nbsp;
                        Annuler
                    </button>
                    &nbsp;
                    { order.mobile && !order.mobile.local ? 
                        <button className="btn btn-info" onClick={ this.handleComplete }>
                            <i className="fa fa-truck"></i>
                            &nbsp;
                            Envoyer au mobile
                        </button>: 
                        <button className="btn btn-success" onClick={ this.handleComplete }>
                            <i className="fa fa-check"></i>
                            &nbsp;
                            Accepter les travaux
                        </button>}
                </span>
                <div className="clearfix" />
            </div>
        </div>
    }

    renderEstimate() {
        
        const { order, configuration } = this.state
        
        let { markup, labor = 100, parts = 30, ldws = 165, rainSensor = 165 } = configuration
        
        markup *= 1
        labor *= 1
        parts *= 1
        ldws *= 1
        rainSensor *= 1
        
        const charges = []

        const item = order.selected 
        
        const key = `${ item.prefix.code }${ item.number.toString().padStart(6, '0') }${ item.color }${ item.attachment ? 'Y': 'N'}`
        const pricing = ((order.pricing || {})[key] || {}).price

        const estimate = pricing && markup ? ((pricing * (100 + markup)) / 100) : pricing

        let total = pricing, clientTotal = estimate

        charges.push(<tr key="glassCharge">
            <td>1</td>
            <td>{ key }</td>
            <td align="right">{ pricing.toFixed(2) }$</td>
            <td align="right">{ estimate.toFixed(2) }$</td>
        </tr>)

        

       for (const partInfo of order.parts) {
            const part = partInfo.part

            const option = part.options && part.options.length > 0 ? part.options[0] : null

            const estimate = Math.round(part.price * (100 + markup)) / 100
            
            charges.push(<tr key={ partInfo.id }>
                <td>{ partInfo.quantity }</td>
                <td>
                    { `${ part.type ? part.type.code : 'XX' }${ part.number ? part.number.toString().padStart(6, '0') : 'N/A' }` }
                    <br/>
                    { option ? option.manufacturer.name : 'N/A'}
                    <br/>
                    { option ? option.number : 'N/A' }
                </td>
                <td align="right">{ part.price.toFixed(2) }$</td>
                <td align="right">{ estimate.toFixed(2) }$</td>
            </tr>)

            total += (partInfo.quantity * part.price)
            clientTotal += (partInfo.quantity * estimate)
        }

        const { other = [] } = order

        for (const part of other) {
            
            const estimate = Math.round(part.price * (100 + markup)) / 100
            
            charges.push(<tr key={ `other-${ part.part }` }>
                <td>{ part.quantity }</td>
                <td>
                    { part.part }
                    <br />
                    { part.description }
                </td>
                <td align="right">{ part.price.toFixed(2) }$</td>
                <td align="right">{ estimate.toFixed(2) }$</td>
            </tr>)

            total += (part.quantity * part.price)
            clientTotal += (part.quantity * estimate)
        }

        total += labor
        clientTotal += labor
        total += parts
        clientTotal += parts
        
        charges.push(<tr key="labor">
                <td>1</td>
                <td>Main-d'oeuvre</td>
                <td align="right">{ labor.toFixed(2) }$</td>
                <td align="right">{ labor.toFixed(2) }$</td>
            </tr>)

        
        charges.push(<tr key="parts">
            <td>1</td>
            <td>Pièces</td>
            <td align="right">{ parts.toFixed(2) }$</td>
            <td align="right">{ parts.toFixed(2) }$</td>
        </tr>)

        if (ldws > 0 && order.job.qualifiers.indexOf('LDWS') !== -1) {
            total += ldws
            clientTotal += ldws
        
            charges.push(<tr key="ldws">
                <td>1</td>
                <td>Calibration LDWS</td>
                <td align="right">{ ldws.toFixed(2) }$</td>
                <td align="right">{ ldws.toFixed(2) }$</td>
            </tr>)
        }

        if (rainSensor > 0 && order.job.qualifiers.indexOf('Rain Sensor') !== -1) {
            total += rainSensor
            clientTotal += rainSensor
        
            charges.push(<tr key="rainSensor">
                <td>1</td>
                <td>Calibration Rain Sensor</td>
                <td align="right">{ rainSensor.toFixed(2) }$</td>
                <td align="right">{ rainSensor.toFixed(2) }$</td>
            </tr>)
        }

        
        charges.push(<tr key="total">
            <td>&nbsp;</td>
            <td><strong>Total</strong></td>
            <td align="right"><strong>{ total.toFixed(2) }$</strong></td>
            <td align="right"><strong>{ clientTotal.toFixed(2) }$</strong></td>
        </tr>)

        return <div key="estimate">
            <h2>Estimé final</h2>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Qté.</th>
                        <th>Description</th>
                        <th align="right">Prix coûtant</th>
                        <th align="right">Prix client</th>
                    </tr>
                </thead>
                <tbody>
                    { charges }
                </tbody>
            </table>
        </div>
        
    }

    renderMobileSummary() {
        const { order } = this.state

        const { mobile = null, local = false, dates = [], notes = '' } = order.mobile || {}
        
        return <div key="mobile">
            <h2>Information pour mobile</h2>
            { local ? <div className="text-success">
                Les travaux seront effectués à l'interne

            </div>:
            <table className="table table-responsive">
                <tbody>
                    <tr>
                        <td align="right"><strong>Mobile</strong></td>
                        <td>{ mobile.name }</td>
                    </tr>
                    <tr>
                        <td align="right"><strong>Dates proposés</strong></td>
                        <td>{ dates.map(date => <div key={ `${ date.datePart } ${ date.timePart }` }>{ Application.date(`${ date.datePart } ${ date.timePart }`) } ({ Application.since(`${ date.datePart } ${ date.timePart }`)})</div>) }</td>
                    </tr>
                    <tr>
                        <td align="right"><strong>Notes</strong></td>
                        <td>{ notes }</td>
                    </tr>
                </tbody>
            </table>
            }
            <button className="btn btn-primary pull-right" onClick={ this.handleMobileChange }>Modifier</button>
            <div className="clearfix"></div>
        </div>
    }

}