import React from 'react'
import { DropdownEdit } from '../../../editors/dropdown'
import { TextEdit } from '../../../editors/text'
import { WheelsEditor } from './wheels'
import { PartsEditor } from './parts'

const currentYear = (new Date()).getFullYear()
const sorter = (a, b) => a < b ? -1 : 1
const unique = (list, selector) => [... new Set(list.map(selector).filter(value => Boolean(value) && value.length > 0).sort(sorter))]

export class KitEdit extends React.Component {
    constructor(props) {
        super(props)
        const year = (new Date()).getFullYear()

        const { 
            modification = '', 
            brand = '', 
            vehicleMake = '', 
            vehicleModel = '', 
            fromYear = year, 
            toYear = year,
            restrictions = '',
            parts = [],
            wheels = [],
            workHours = '',
            billedHours = '', 
            alignmentRequired = true
        } = props.kit || {}

        const state = { modification, brand, vehicleMake, vehicleModel, fromYear, toYear, restrictions, workHours, billedHours, parts, wheels, alignmentRequired, changed: false }


        state.validation = this.validate(state)

        this.state = state
    }

    extract(state = {}) {
        state = { ... this.state, ... state }

        const parsedFrom = Number.parseInt(state.fromYear || '')
        const parsedTo = Number.parseInt(state.toYear || '')
        const parsedWorkHours = Number.parseFloat(state.workHours || '')
        const parsedBilledHours = Number.parseFloat(state.billedHours || '')

        return { 
            brand: state.brand || null,
            modification: state.modification || null,
            vehicleMake: state.vehicleMake || null, 
            vehicleModel: state.vehicleModel || null, 
            fromYear: !Number.isNaN(parsedFrom) ? parsedFrom : null, 
            toYear: !Number.isNaN(parsedTo) ? parsedTo : null,
            restrictions: state.restrictions || null,
            parts: state.parts || [],
            wheels: state.wheels || [],
            alignmentRequired: state.alignmentRequired,
            workHours: !Number.isNaN(parsedWorkHours) ? parsedWorkHours : null,
            billedHours: !Number.isNaN(parsedBilledHours) ? parsedBilledHours : null
        }

        
    }

    validate(state = {}) {
        state = { ... this.state, ... state }

        const kit = this.extract(state)

        const validation = { errors: {}, warnings: {} }

        if (kit.modification === null || kit.modification.length < 3)
            validation.errors.modification = 'Description de la modification requise'

        if (kit.brand === null || kit.brand.length < 3)
            validation.errors.brand = 'Marque du kit requise'
        
        if (kit.vehicleMake === null || kit.vehicleMake.length < 3)
            validation.errors.vehicleMake = 'Marque du véhicule requise'

        if (kit.vehicleModel === null || kit.vehicleModel.length < 3)
            validation.errors.vehicleModel = 'Modèle du véhicule requis'

        if (kit.fromYear === null || kit.fromYear < 1995 || kit.fromYear > currentYear + 1)
            validation.errors.fromYear = 'Année invalide'

        if (kit.toYear !== null && (kit.toYear < 1995 || kit.toYear > currentYear + 1 || kit.toYear < kit.fromYear))
            validation.errors.toYear = 'Année invalide'
        
        if (kit.parts.length === 0)
            validation.warnings.parts = 'Au moins une pièce est requise'
        
        if (kit.wheels.length === 0)
            validation.warnings.wheels = 'Au moins une taille de roue est requise'

        if (kit.workHours === null || kit.workHours <= 0)
            validation.warnings.workHours = 'Heures invalides'
            
        if (kit.billedHours === null || kit.billedHours <= 0)
            validation.warnings.billedHours = 'Heures invalides'
        
        return validation
    }

    handleChange(field, value) {
        const state = {}
        state[field] = value
        state.validation = this.validate(state)
        state.modified = true     

        this.setState(state)
    }

    handleSave = () => {
        const kit = this.extract()

        return this.props.onSave(kit)
    }

    handlePartsChanged = parts => {
        this.handleChange('parts', parts)
    }

    handleWheelsChanged = wheels => {
        this.handleChange('wheels', wheels)
    }

    handleCancel = () => { 
        if (!this.state.modified || confirm('Voulez-vous abandonner les changements?'))
            this.props.onCancel()
    }

    render() {
        const { modification, brand, vehicleMake, vehicleModel, 
            fromYear, toYear, validation, restrictions, parts, wheels, workHours, billedHours, modified, alignmentRequired } = this.state


        const { errors, warnings } = validation
        const years = []

        const valid = Object.keys(errors).length === 0

        for (let year = currentYear + 1; year >= 2007; year --) years.push(year.toString())

        return <div>
            <h2>
                { this.props.title || null }
                <div className="pull-right">
                    <button className="btn btn-primary" onClick={ this.handleCancel }>
                        { modified ? <>
                            <i className="fa fa-close"></i>
                            &nbsp;
                            Annuler
                        </> : <>
                            <i className="fa fa-caret-left"></i>
                            &nbsp;
                            Retour
                        </> }
                    </button>
                    {
                        modified ? <>
                            &nbsp;
                            <button className="btn btn-primary" disabled={ !valid } onClick={ this.handleSave }>
                                { this.props.kit.id ? 'Enregistrer' : 'Ajouter' }
                            </button>    
                        </>: null
                    }
                </div>
                <div className="clearfix"></div>
            </h2>
            <div className="row">
                <div className="col-xs-12">
                    <h3>Description du kit</h3>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <DropdownEdit options={ this.brands } value={ brand } 
                        onChange={ value => this.handleChange('brand', value) } 
                        error={ errors.brand } label="Marque du kit" />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <DropdownEdit options={ this.modifications } value={ modification } 
                        onChange={ value => this.handleChange('modification', value) } 
                        error={ errors.modification } label="Modification" />
                </div>
                <div className="col-xs-12">
                    <h3>Véhicules</h3>
                </div>
                <div className="col-sm-6 col-xs-12">
                    <DropdownEdit options={ this.vehicleMakes } value={ vehicleMake } 
                        onChange={ value => this.handleChange('vehicleMake', value) } 
                        error={ errors.vehicleMake } label="Marque de véhicule" />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <DropdownEdit options={ this.vehicleModels } value={ vehicleModel } 
                        onChange={ value => this.handleChange('vehicleModel', value) } 
                        error={ errors.vehicleModel } label="Modèle de véhicule" />                
                </div>
                <div className="col-sm-6 col-xs-12">
                    <DropdownEdit options={ years } value={ fromYear } 
                        onChange={ value => this.handleChange('fromYear', value) } 
                        error={ errors.fromYear } label="Année (début)" />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <DropdownEdit options={ years } value={ toYear } 
                        onChange={ value => this.handleChange('toYear', value) } 
                        error={ errors.toYear } label="Année (fin)" />
                </div>
                <div className="col-xs-12">
                    <h3>Information pour la vente</h3>
                </div>
                
                <div className="col-sm-6 col-xs-12">
                    <TextEdit value={ workHours || '' } 
                        error={ errors.workHours } warning={ warnings.workHours }
                        onChange={ value => this.handleChange('workHours', value) } 
                        label="Main-d'oeuvre minimale" />
                </div>
                <div className="col-sm-6 col-xs-12">
                    <TextEdit value={ billedHours || '' } error={ errors.billedHours } warning={ warnings.billedHours }
                        onChange={ value => this.handleChange('billedHours', value) } 
                        label="Main-d'oeuvre suggérée" />
                </div>
                <div className="col-xs-12">
                    <TextEdit value={ restrictions || '' } 
                        onChange={ value => this.handleChange('restrictions', value) } 
                        label="Contraintes (limites ou avertissements)" />
                </div>
                <div className="col-xs-12">
                    <div className="checkbox">
                        <label htmlFor="alignmentRequired">
                            <input type="checkbox" name="alignmentRequired" id="alignmentRequired" checked={ alignmentRequired } 
                                onChange={ event => this.handleChange('alignmentRequired', event.target.checked) }/>
                            <span>Alignement nécessaire</span>
                        </label>
                    </div>
                </div>
                
                <div className="col-xs-12">
                    <h4>Pièces</h4>
                    { errors.parts ? <div className="bg-danger">{ errors.parts }</div> : null }
                    { warnings.parts ? <div className="bg-warning">{ warnings.parts }</div> : null }
                    <PartsEditor key="parts" parts={ parts } onChanged={ this.handlePartsChanged } />
                </div>
                <div className="col-xs-12">
                    <h4>Tailles de roues</h4>
                    { errors.wheels ? <div className="bg-danger">{ errors.wheels }</div> : null }
                    { warnings.wheels ? <div className="bg-warning">{ warnings.wheels }</div> : null }
                    <WheelsEditor wheels={ wheels } onChanged={ this.handleWheelsChanged } onChanged={ this.handleWheelsChanged } />
                </div>
                <div className="col-xs-12">
                    <button className="pull-right btn btn-primary" disabled={ !valid } onClick={ this.handleSave }>
                        { this.props.kit.id ? 'Enregistrer' : 'Ajouter' }
                    </button>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
        
    }

    get kits() {
        return this.props.kits
    }

    get brands() {
        return unique(this.kits, kit => kit.brand)
    }

    get modifications() {
        return unique(this.kits, kit => kit.modification)
    }

    get vehicleMakes() {
        return unique(this.kits, kit => kit.vehicleMake)
    }

    get vehicleModels() {
        return unique(this.kits.filter(kit => kit.vehicleMake === this.state.vehicleMake), kit => kit.vehicleModel)
    }
}

