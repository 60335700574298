import React from 'react'
import { wheelSortFunction } from './wheel-sort'

export class ManagerKitSummary extends React.Component {
    constructor(props) {
        super(props)
    }

    renderYear(kit) {
        if (kit.fromYear && kit.toYear)
            return `${ kit.fromYear }-${ kit.toYear }`
        if (kit.fromYear && !kit.toYear)
            return `${ kit.fromYear }+`
        if (!kit.fromYear && kit.toYear)
        return `...-${ kit.toYear }`
    }

    renderPrice(kit) {
        if (!kit || kit.parts.length === 0) return '0.00$'

        return (kit.parts.reduce( (accumulator, part) => {
            accumulator += part.quantity * part.price
            return accumulator
        }, 0)).toFixed(2) + '$'

    }

    renderParts() {
        const { kit } = this.props
        const { parts = [] } = kit
        const hasMultipleParts = Boolean(parts.find(part => part.quantity > 1))

        if (parts.length === 0) 
            return <div className="text-info">Information à venir</div>

        return <table className="table table-condensed table-responsive" style={{ backgroundColor: 'white' }}>
                    <thead>
                        <tr>
                            { hasMultipleParts ? <th>Qté.</th> : null }
                            <th>Pièce</th>
                            <th><div className="text-right">Prix</div></th>
                            { hasMultipleParts ? <th className="text-right">Total</th> : null }
                        </tr>
                    </thead>
                    <tbody>
                        { parts.map((part, index) => 
                            <tr key={ index }>
                                { hasMultipleParts ? <td>{ part.quantity }</td>: null }
                                <td>{ part.identifier }</td>
                                <td align="right">{ part.price.toFixed(2) }$</td>
                                { hasMultipleParts ? <td align="right">{ (part.quantity * part.price).toFixed(2) }$</td>: null }
                            </tr>) }
                        { parts.length > 1 ? <tr>
                            <td colSpan={ hasMultipleParts ? 3 : null } align="right">Total:</td>
                            <td align="right">{ parts.reduce((accumulator, part) => accumulator + (part.quantity * part.price), 0).toFixed(2) }$</td>
                        </tr>: null }
                    </tbody>
                </table>

    }

    renderWheels() {
        const { kit } = this.props
        const { wheels = [] } = kit

        wheels.sort(wheelSortFunction)

        const wheelRows = wheels.reduce((list, wheel, index) => {
            list.push(<tr key={ index }>
                <td><strong>{ wheel.diameter || '-' }</strong></td>
                <td>{ wheel.originalWidth || '-' }</td>
                <td>{ wheel.minOffset > 0 ? '+' : null }{ wheel.minOffset || '0' }</td>
                <td>{ wheel.width || '-' }</td>
                <td>{ wheel.maxOffset > 0 ? '+' : null }{ wheel.maxOffset || '0' }</td>
                <td>{ wheel.tire || '-' }</td>
            </tr>)

            return list
        }, [])

        if (wheels.length === 0) 
            return <div className="text-info">Information à venir</div>

            return <table className="table table-condensed table-responsive" style={{ backgroundColor: 'white' }}>
            <thead>
                <tr>
                    <th rowSpan="2">Diamètre</th>
                    <th colSpan="2">Origine</th>
                    <th colSpan="2">Maximum recommandé</th>
                    <th rowSpan="2">Taille maximale<br/>des pneus</th>
                </tr>
                <tr>
                    <th>Largeur</th>
                    <th>Offset</th>
                    <th>Largeur</th>
                    <th>Offset</th>
                </tr>
            </thead>
            <tbody>
                { wheelRows }
            </tbody>
        </table>
    }

    renderDetails() {
        const { kit, open } = this.props
        
        if (!open) return null

        return <tr className="active">
            <td colSpan="5">
                <div className="row">
                    <div className="col-xs-6">
                        <strong>Main-d'oeuvre minimale</strong><br/>
                        { kit.workHours || '-' }
                    </div>
                    <div className="col-xs-6">
                        <strong>Main-d'oeuvre suggérée</strong><br/>
                        { kit.billedHours || '-' }
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <h4>Pièces</h4>
                        { this.renderParts()  }

                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <h4>Tailles des roues</h4>
                        { this.renderWheels() }
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-12">
                        <div className="pull-right">
                            <button className="btn btn-danger btn-xs" onClick={ this.props.onDelete }>
                                <i className="fa fa-close"></i>
                                &nbsp;
                                Supprimer
                            </button>
                            &nbsp;
                            <button className="btn btn-primary btn-xs" onClick={ this.props.onToggle }>
                                <i className="fa fa-caret-up"></i>
                                &nbsp; Fermer
                            </button>
                        </div>
                    </div>
                </div>
            </td>
        </tr>

    }

    render() {
        const { kit, open } = this.props
        
        let details = this.renderDetails() 
        
        return <>
            <tr>
                <td>{ kit.modification }</td>
                <td>{ kit.brand }</td>
                <td>
                    { kit.vehicleMake } { kit.vehicleModel } { this.renderYear(kit) }
                    { kit.restrictions ? <div className="text-danger"><strong>{ kit.restrictions }</strong></div> : null }
                </td>
                <td>{ this.renderPrice(kit) }</td>
                <td align="right">
                    <button className="btn btn-primary btn-xs" onClick={ () => this.props.onEdit() }>
                        <i className="fa fa-pencil"></i>
                        &nbsp;
                        Modifier
                    </button>
                    &nbsp;
                    { !open ? 
                    <button className="btn btn-primary btn-xs" onClick={ this.props.onToggle }>
                        <i className="fa fa-caret-down"></i>
                        &nbsp;
                        Détails
                    </button> : null }
 
                </td>
            </tr>
            { details }
            
        </>
    }
}