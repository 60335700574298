import React from 'react'
import { Application } from '../../Application'
import { GlassOrderSharedSummary } from './order/shared-summary'


class OrderView extends React.Component {
    constructor(props) {
        super(props)

        const { mobile = {}} = props.order
        this.state = { selectedDate: mobile.selectedDate || null, reject: false }
    }

    handleDatePicked(event, selectedDate) {
        if (!event.target.checked) return

        this.setState({ selectedDate, reject: selectedDate === null })
        
            
    }

    handleAccept = event => {
        const { selectedDate, reject } = this.state

        this.props.onOrderAccepted({ order: this.props.order, selectedDate, reject })
    }

    handleComplete = event => {
        this.props.onOrderCompleted(this.props.order.id)
    }

    render() {
        const { selectedDate } = this.state
        const { mobile = {}, status} = this.props.order
        const selectedKey = selectedDate ? `${ selectedDate.datePart } ${ selectedDate.timePart }` : null
        const dates = (mobile.dates || []).map(
            date => {
                const key = `${ date.datePart } ${ date.timePart }`
                return <div className="radio" key={ key }>
                    <label>
                        <input type="radio" name="mobile-date" value={ key } checked={ selectedKey===key } onChange={ event => this.handleDatePicked(event, date) } />
                        { Application.date(key) } ({ Application.since(key)})
                    </label>
                </div>
            }
        )

        let actions
        if (status === 'schedule') {
            actions = <div className="alert alert-info">
                <h3>Demande de rendez-vous</h3>
            { mobile.notes ? <span><strong>Note: </strong>{ mobile.notes }</span> : null }
            <hr/>
            <h4>Sélectionnez la date de rendez-vous idéale</h4>
            { dates }
            <div className="radio">
                <label>
                    <input type="radio" name="mobile-date" checked={ this.state.reject } onChange={ event => this.handleDatePicked(event, null) } />
                    Aucune de ces dates n'est acceptable
                </label>
            </div>
            { this.state.selectedDate || this.state.reject ? <div>
                <hr/>
                { this.state.selectedDate ? 
                    <span className="text-success">Le travail sera effecté { Application.date(`${ this.state.selectedDate.datePart } ${ this.state.selectedDate.timePart }`) } ({ Application.since(`${ this.state.selectedDate.datePart } ${ this.state.selectedDate.timePart }`) }) </span>:
                    <span className="text-danger">Les dates proposées seront rejetées et la commande retournera en évaluation</span>}
                <button className="btn btn-info pull-right" onClick={ this.handleAccept }>Accepter</button>
                <div className="clearfix"></div>
            </div>: null}
                </div> }
        else if (status === 'scheduled') {
            actions = <div className="alert alert-success">
                { mobile.notes ? <span><strong>Note: </strong>{ mobile.notes }</span> : null }
                <hr/>
                <h4>Commande cédulée</h4>
                <strong>{ selectedKey ? <span>{ Application.date(selectedKey) }({ Application.since(selectedKey)})</span>: null }</strong>
                <hr/>
                <button className="pull-right btn btn-success" onClick={ this.handleComplete }>Compléter</button>
                <div className="clearfix"></div>
            </div>
        }



        return <div>
            <h2>
                Commande de pare-brise
                <button className="pull-right btn btn-primary" onClick={ this.props.onClosed }>
                    <i className="fa fa-caret-left"/>
                    &nbsp;Retour
                </button>
            </h2>
            <div className="clearfix"></div>
            <br/>
            { actions }
            <GlassOrderSharedSummary order={ this.props.order } />
        </div>
    }
}


export class VehicleGlassMobile extends React.Component {
    constructor(props) {
        super(props)

        this.state = { orders: null, order: null }
    }

    async componentDidMount() {
        return this.refreshList()        
    }

    async refreshList() {
        const result = await Application.post({ path: '/glass-order/mobile' })
        
        const { orders } = result || []

        const order = this.props.order ? orders.find(order => order.id === this.props.order) : null

        this.setState({ orders, order })

    }

    handleOrderAccepted = async ({ order, selectedDate, reject }) => {
        
        const { success } = await Application.get({ path: '/glass-order/accept', query: { order: order.id, date: reject ? null : JSON.stringify(selectedDate) }})

        await this.refreshList()
    }

    handleOrderCompleted = async (order) => {
        const { success } = await Application.get({ path: '/glass-order/complete', query: { order }})

        await this.refreshList()
    }

    handleOrderClosed = () => {
        this.setState({ order: null })

        if (this.props.onOrderClosed)
            this.props.onOrderClosed()
    }

    render() {
        if (!this.state.orders)
            return <div className="waiting"></div>

        if (this.state.order)
            return <OrderView order={ this.state.order } onOrderAccepted={ this.handleOrderAccepted } onOrderCompleted={ this.handleOrderCompleted } onClosed={ this.handleOrderClosed }/>

        return this.renderList()
        
    }

    renderStatus(status) {
        switch (status) {
            case 'schedule':
                return <span className="text-info">Demande de rendez-vous mobile</span>
            case 'scheduled':
                return <span className="text-info">Rendez-vous cédulé</span>
            default:
                return <span className="text-muted">{ status }</span>
        }
    }

    renderDate(order) {
        if (order.status === 'schedule')
            return <span>Créé le { order.date }</span>

        const { selectedDate } = order.mobile || {}

        if (selectedDate) {
            const key = `${ selectedDate.datePart } ${ selectedDate.timePart }`
            return <span><strong className="text-success">{ Application.date(key) } ({ Application.since(key) })</strong></span>
        }

        return null
    }


    renderList() {
        const { orders } = this.state
        
        const children = orders.length === 0 ? 
            <tr><td colSpan="5" align="center" className="text-success">Aucune commande mobile disponible</td></tr>
        : orders.map(order => 
            <tr key={ order.id }>
                <td>{ this.renderStatus(order.status) }
                    <br/>
                    { this.renderDate(order)}
                    
                </td>
                <td>{ order.owner }</td>
                <td>
                    <strong>{ order.details ? order.details.name : 'N/A' }</strong><br/>
                    { order.details ? order.details.phone : 'N/A' }<br/>
                </td>
                <td>
                    <span className="label label-info">{ order.job.repair ? 'Réparer' : 'Remplacer' }</span>
                    &nbsp;
                    <strong>{ order.vehicle ? order.vehicle.description : 'N/A' }</strong>
                    
                    
                    <br/>
                    
                    { order.job ? `${ order.job.opening.description } ${ order.job.position ? order.job.position.qualifier.value : ''} ${ order.job.side ? order.job.side.qualifier.value : ''} ` : null}
                    { order.job && order.job.color ? order.job.color.name : 'N/A'}
                </td>
                <td>
                    <a onClick={ () => this.setState({ order }) }>
                        <i className="fa fa-search fa-2x"/>
                    </a>
                </td>
            </tr>    
        )

        
      

        return  <div> 
            <h2>Commandes mobiles</h2>
            <a className="btn btn-primary pull-right" onClick={ this.props.onHomeClicked }>
                <i className="fa fa-home"></i>
                &nbsp;
                Tableau de bord
            </a>
            <div className="clearfix"></div>
            <hr/>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Statut</th>
                        <th>Lieu</th>
                        <th>Client</th>
                        <th>Travaux</th>
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    { children }
                </tbody>
            </table>
        </div>

        
    }

}
