import React from 'react'
import { Application } from '../../../../Application'
import { wheelSortFunction } from './wheel-sort'


export class ConsumerKitSummary extends React.Component {
    constructor(props) {
        super(props)

        this.state = { ordering: false }
    }

    handleOrder = () => {
        this.setState({ ordering: !this.state.ordering })
    }

    renderYear(kit) {
        if (kit.fromYear && kit.toYear)
            return `${ kit.fromYear }-${ kit.toYear }`
        if (kit.fromYear && !kit.toYear)
            return `${ kit.fromYear }+`
        if (!kit.fromYear && kit.toYear)
        return `...-${ kit.toYear }`
    }

    partPrice() {
        const { kit } = this.props 

        return kit.parts.reduce( (accumulator, part) => {
            accumulator += part.quantity * part.price
            return accumulator
        }, 0)
    }


    clientPrice() {
        const { kit, configuration } = this.props 
        const { labor, alignment } = configuration

        if (!kit || kit.parts.length === 0 || !labor || !alignment) return Number.NaN

        let price = this.partPrice()

        price += (kit.billedHours * labor) || 0
    
        if (kit.alignmentRequired !== false)
            price += alignment

        return price
    }

    renderParts() {
        const { kit } = this.props
        const { parts = [] } = kit
        const hasMultipleParts = Boolean(parts.find(part => part.quantity > 1))

        if (parts.length === 0) 
            return <div className="text-info">Information à venir</div>

        return <table className="table table-condensed table-responsive" style={{ backgroundColor: 'white' }}>
                    <thead>
                        <tr>
                            { hasMultipleParts ? <th>Qté.</th> : null }
                            <th>Pièce</th>
                            <th><div className="text-right">Prix vendant</div></th>
                            { hasMultipleParts ? <th className="text-right">Total</th> : null }
                        </tr>
                    </thead>
                    <tbody>
                        { parts.map((part, index) => 
                            <tr key={ index }>
                                { hasMultipleParts ? <td>{ part.quantity }</td>: null }
                                <td>{ part.identifier }</td>
                                <td align="right">{ part.price.toFixed ? part.price.toFixed(2) : 'N/A' }$</td>
                                { hasMultipleParts ? <td align="right">{ part.quantity > 0 && part.price > 0 ? (part.quantity * part.price).toFixed(2) : 'N/A' }$</td>: null }
                            </tr>) }
                        { parts.length > 1 ? <tr>
                            <td colSpan={ hasMultipleParts ? 3 : null } align="right">Total:</td>
                            <td align="right">{ parts.reduce((accumulator, part) => accumulator + (part.quantity * part.price), 0).toFixed(2) }$</td>
                        </tr>: null }
                    </tbody>
                </table>

    }

    renderWheels() {
        const { kit } = this.props
        const { wheels = [] } = kit

        wheels.sort(wheelSortFunction)

        const wheelRows = wheels.reduce((list, wheel, index) => {
            list.push(<tr key={ index }>
                <td><strong>{ wheel.diameter || '-' }</strong></td>
                <td>{ wheel.originalWidth || '-' }</td>
                <td>{ wheel.minOffset > 0 ? '+' : null }{ wheel.minOffset || '0' }</td>
                <td>{ wheel.width || '-' }</td>
                <td>{ wheel.maxOffset > 0 ? '+' : null }{ wheel.maxOffset || '0' }</td>
                <td>{ wheel.tire || '-' }</td>
            </tr>)

            return list
        }, [])

        

        if (wheels.length === 0) 
            return <div className="text-info">Information à venir</div>

            return <table className="table table-condensed table-responsive" style={{ backgroundColor: 'white' }}>
            <thead>
                <tr>
                    <th rowSpan="2">Diamètre</th>
                    <th colSpan="2">Origine</th>
                    <th colSpan="2">Maximum recommandé</th>
                    <th rowSpan="2">Taille maximale<br/>des pneus</th>
                </tr>
                <tr>
                    <th>Largeur</th>
                    <th>Offset</th>
                    <th>Largeur</th>
                    <th>Offset</th>
                </tr>
            </thead>
            <tbody>
                { wheelRows }
            </tbody>
        </table>
    }

    renderEstimate() {
        const { kit, configuration } = this.props
        let { alignment, labor } = configuration

        const alignmentRequired = kit.alignmentRequired !== false
        
        const laborTotal = kit.billedHours * labor
        
        const clientTotal = this.clientPrice()

        if (Number.isNaN(clientTotal))
            return <div className="alert alert-info">
                Configuration incomplète. L'outil ne peut pas calculer l'estimé des travaux avant d'être configuré
                <br/>
                <a className="btn btn-info pull-right" target="_blank" href={ Application.url({ path: '/configure/suspension' }) }>Configurer</a>
                <div className="clearfix"></div>
            </div>

        return <table className="table table-condensed" style={{ backgroundColor: 'white' }}>
            <tbody>
                <tr>
                    <td align="right">Pièces (prix vendant)</td>
                    <td>{ this.partPrice().toFixed(2) }$</td>
                </tr>
                { alignmentRequired ? 
                <tr>
                    <td align="right">Alignement</td>
                    <td>{ alignment.toFixed(2) }$</td>
                </tr> : null }
                <tr>
                    <td align="right">
                        Main-d'oeuvre facturable<br/>
                        ({ kit.billedHours }h x { labor.toFixed(2) }$ )
                        </td>
                    <td>{ laborTotal.toFixed(2) }$</td>
                </tr>
                
                <tr>
                    <td align="right" className="text-success"><strong>Total client</strong></td>
                    <td className="text-success">{ clientTotal.toFixed(2) }$</td>
                </tr>
            </tbody>
        </table>
    }

    renderDetails() {
        const { kit, open } = this.props

        const { ordering } = this.state

        const partSummary = kit.parts.map(part => `${ part.quantity }X ${ part.identifier }`).join(', ')
        
        if (!open) return null

        return <tr className="active">
            <td colSpan="4">
                <div className="row">
                    <div className="col-xs-6">
                        <strong>Main-d'oeuvre minimale</strong><br/>
                        { kit.workHours || '-' }
                    </div>
                    <div className="col-xs-6">
                        <strong>Main-d'oeuvre suggérée</strong><br/>
                        { kit.billedHours || '-' }
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <h4>Pièces</h4>
                        { this.renderParts()  }
                        <h4>Tailles des roues</h4>
                        { this.renderWheels() }
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <h4>Estimé</h4>
                        { this.renderEstimate() }
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-xs-12">
                        <div className="pull-right">
                            { !ordering ? 
                            <button className="btn btn-success btn-xs" onClick={ this.handleOrder }>
                                <i className="fa fa-cart"></i>
                                &nbsp;
                                Commander
                            </button> : null }
                            &nbsp;
                            <button className="btn btn-primary btn-xs" onClick={ this.props.onToggle }>
                                <i className="fa fa-caret-up"></i>
                                &nbsp; Fermer
                            </button>
                        </div>
                        
                    </div>
                    <div className="clearfix"></div>
                    { ordering ? <div className="alert alert-success">
                        <h3>Commandez chez <strong>Grandwest</strong></h3>
                        <div>N'oubliez pas les accessoires (pneus, marche-pieds, etc.)</div>
                        <textarea className="form-control" rows="3" value={ partSummary } />
                        <hr/>
                        <a href="https://www.distam.ca/distam/web_items/web_att.asp?attid=Y%21%21m%408d%2Ew%C2%A3" target="_new" className="btn btn-success btn-xs animated pulse">
                                <i className="fa fa-shopping-cart"></i>
                                &nbsp;
                                Catalogue Grandwest
                        </a>
                        <div className="pull-right">
                            <button className="btn btn-default btn-xs" onClick={ this.handleOrder }>
                                <i className="fa fa-close"></i>
                                &nbsp;
                                Fermer
                            </button>
                            &nbsp;
                            <button className="btn btn-success btn-xs" disabled={ true }>
                                <i className="fa fa-check"></i>
                                &nbsp;
                                Envoyer
                            </button>
                        </div>
                        <div className="clearfix"></div>
                    </div> : null }
                </div>
            </td>
        </tr>

    }

    render() {
        const { kit, open } = this.props
        
        let details = this.renderDetails() 
        
        const clientTotal = this.clientPrice() //, costTotal = this.costPrice()
        
        return <>
            <tr>
                <td>
                    <div>{ kit.modification }</div>
                    { kit.restrictions ? <div className="text-danger"><strong>{ kit.restrictions }</strong></div> : null }
                </td>
                <td>{ kit.brand }</td>
                
                <td>{ clientTotal > 0 ? `${ clientTotal.toFixed(2) }$` : <a href={ Application.url({ path: '/configure/suspension' }) }>Configurer</a> }</td>
                <td align="right">
                    { !open ? 
                    <button className="btn btn-primary btn-xs" onClick={ this.props.onToggle }>
                        <i className="fa fa-caret-down"></i>
                        &nbsp;
                        Détails
                    </button> : null }
 
                </td>
            </tr>
            { details }
            
        </>
    }
}

//<td>{ costTotal > 0 ? `${ costTotal.toFixed(2) }$` : <a href={ Application.url({ path: '/configure/suspension' }) }>Configurer</a> }</td>

/*<tr>
                    <td align="right"><strong>Total coûtant</strong></td>
                    <td>{ costTotal.toFixed(2) }$</td>
                </tr>*/