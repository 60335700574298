import React from 'react'
import { Application } from '../../../Application'

export class GlassOrderSharedSummary extends React.Component {
    constructor(props) {
        super(props)

        this.state = { order: props.order, configuration: props.configuration }
   
    }


    render() {
        if (!this.state.order)
            return <div className="waiting"></div>

        const { order, configuration } = this.state
    
        const summary = []

        if (order.job.repair !== true) {
            summary.push(this.renderGlassSummary())
            summary.push(this.renderPartsSummary())
            
            if (configuration)
                summary.push(this.renderEstimate())
        }

        if (order.mobile && !order.mobile.local)
            summary.push(this.renderMobileSummary())
     
        return <div>
            <h2>Sommaire</h2>
            <table className="table table-responsive">
                <tbody>
                    <tr><td align="right"><strong>Véhicule</strong></td><td>{ order.vehicle.description }</td></tr>
                    <tr><td align="right"><strong>Travaux</strong></td><td>
                        { order.job.opening.description } 
                        { order.job.side ? ` - ${ order.job.side.qualifier.value }` : '' }
                        { order.job.position ? ` - ${ order.job.position.qualifier.value }` : '' }
                        { order.job.color ? ` (${ order.job.color.name })` : '' }</td></tr>
                    <tr><td align="right"><strong>Caractéristiques</strong></td><td>
                        { order.job.repair === true ? 
                            `Le verre peut être réparé` : 
                            (order.job && order.job.qualifiers && order.job.qualifiers.length > 0 ? order.job.qualifiers.join(', ') : 'Le verre n\'a pas de caractéristique visible') }
                        </td></tr>
                        { configuration ? this.renderDetails() : null }
                </tbody>
            </table>
            { summary }
            
        </div>
    
    }


    renderGlassSummary() {
        const { order, configuration } = this.state
        const item = order.selected 
        
        const availability = item.pricing && item.pricing.availability && item.pricing.availability.name ? <div className='text-muted'>Disponible</div>: null
        const key = `${ item.prefix.code }${ item.number.toString().padStart(6, '0') }${ item.color }${ item.attachment ? 'Y': 'N'}`

        const pricing = (order.pricing || {})[key]

        const msrp = item.pricing && item.pricing.price > 0 ? <div className="text-muted">~ { item.pricing.price.toFixed(2) }$</div> : null

        const isANumber = pricing && typeof pricing.price === 'number'
        const estimate = configuration && isANumber && configuration.markup ? <div className="text-success">+{ configuration.markup }% = { ((pricing.price * (100 + configuration.markup)) / 100).toFixed(2) }$</div> : null

        const pricingLabel = isANumber ? <div className="text-success">{ pricing.provider }: { pricing.price.toFixed(2) }$</div> : msrp

        return  <div key="glassSummary">
            <h2>Verre</h2>
            <table className="table table-responsive">
                <tbody>
                    <tr>
                        <td>
                            <strong>{ key }</strong><br/>
                            { availability }
                        </td>
                        <td>
                            { item.qualifiers && item.qualifiers.length > 0 ? <strong>{ item.qualifiers.map(q => q.value).join(', ') }</strong> : null }<br/>
                            { item.missingQualifiers && item.missingQualifiers.length > 0 ? <div><strong className="text-danger">Caractéristiques manquantes: { item.missingQualifiers.join(', ') }</strong></div> : null }<br/>
                        </td>
                        <td>
                            { pricingLabel }
                            { estimate }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
      
    }

    renderPartsSummary() {
        const { order } = this.state
        const { parts = [], other = [] } = order



        let partElements = parts.map((selectedPart, index) => {
            const part = selectedPart.part
            if (!part) return null

            
            const price = part.price ? 
                <div className="text-succes">{ part.price.toFixed(2) }$</div>:
                (option ? (option.pricing['Canada'] ? `~${ option.pricing['Canada'].price.toFixed(2) }$` : null) : null)
            
            const { markup } = this.state.configuration || {}
            const estimate = part.price && markup ? <div className="text-success">+{ markup }% = { ((part.price * (100 + markup)) / 100).toFixed(2) }$</div> : null
            

            const option = part.options && part.options.length > 0 ? part.options[0] : null
            const qualifiers = (part.qualifiers && part.qualifiers.length > 0 ? part.qualifiers.map(q => q.value) : []).join(', ')
            
            return <tr key={ index }>
                <td>{ selectedPart.quantity }</td>
                <td>
                    { `${ part.type ? part.type.code : 'XX' }${ part.number ? part.number.toString().padStart(6, '0') : 'N/A' }` }<br/>
                    { part.type ? part.type.name : 'Part' } { qualifiers.length > 0 ? `- ${ qualifiers }` : null }
                </td>
                <td>
                    { option ? option.manufacturer.name : 'N/A'}<br/>
                    { option ? option.number : 'N/A' }
                </td>
                <td>{ price }{ estimate }</td>
        </tr>})

        for (const otherPart of other) {
            partElements.push(<tr key={ otherPart.part }>
                <td>{ otherPart.quantity }</td>
                <td>{ otherPart.part }</td>
                <td>{ otherPart.description }</td>
                <td>{ (otherPart.price || 0).toFixed(2) }$</td>
            </tr>)
        }

        if (parts.length === 0 && other.length === 0) partElements=<tr><td colSpan="3">Aucune pièce sélectionnée</td></tr>

        return <div key="partSummary">
            <h2>Accessoires sélectionnés</h2>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Quantitée</th>
                        <th>Pièce et type</th>
                        <th>Fabricant</th>
                        <th>Prix</th>
                    </tr>
                </thead>
                <tbody>
                    { partElements }
                </tbody>
            </table>
        </div>
    }
    

    renderEstimate() {
        
        const { order, configuration } = this.state

        const charges = []

        const item = order.selected 

        
        const key = `${ item.prefix.code }${ item.number.toString().padStart(6, '0') }${ item.color }${ item.attachment ? 'Y': 'N'}`
        const pricing = ((order.pricing || {})[key] || {}).price

        const estimate = pricing && configuration.markup ? ((pricing * (100 + configuration.markup)) / 100) : pricing

        let total = pricing, clientTotal = estimate

        charges.push(<tr key="glassCharge">
            <td>1</td>
            <td>{ key }</td>
            <td align="right">{ pricing ? pricing.toFixed(2) : '-' }$</td>
            <td align="right">{ estimate ? estimate.toFixed(2) : '-' }$</td>
        
        </tr>)
       
       const { markup } = this.state.configuration || {}

       for (const partInfo of (order.parts || [])) {
            const part = partInfo.part

            const option = part.options && part.options.length > 0 ? part.options[0] : null

            
            const estimate = Math.round(part.price * (100 + markup)) / 100
            
            charges.push(<tr key={ partInfo.id }>
                <td>{ partInfo.quantity }</td>
                <td>
                    { `${ part.type ? part.type.code : 'XX' }${ part.number ? part.number.toString().padStart(6, '0') : 'N/A' }` }
                    <br/>
                    { option ? option.manufacturer.name : 'N/A'}
                    <br/>
                    { option ? option.number : 'N/A' }
                </td>
                <td align="right">{ part.price.toFixed(2) }$</td>
                <td align="right">{ estimate }$</td>
            </tr>)

            total += (partInfo.quantity * part.price)
            clientTotal += (partInfo.quantity * estimate)
        }

        for (const otherPart of (order.other || [])) {
            const estimate = Math.round(otherPart.price * (100 + markup)) / 100
            charges.push(<tr key={ otherPart.part }>
                <td>{ otherPart.quantity }</td>
                <td>{ otherPart.part }<br/>{ otherPart.description }</td>
                <td align="right">{ otherPart.price.toFixed(2) }$</td>
                <td align="right">{ estimate.toFixed(2) }$</td>
            </tr>)
            
            total += (otherPart.quantity * otherPart.price)
            clientTotal += (otherPart.quantity * estimate)
        }

        
    
        total += configuration.labor || 100
        clientTotal += configuration.labor || 100
        total += configuration.parts || 30
        clientTotal += configuration.parts || 30
    

        charges.push(<tr key="labor">
            <td>1</td>
            <td>Main-d'oeuvre</td>
            <td align="right">{ ( 1 * (configuration.labor || 100)).toFixed(2) }$</td>
    
            <td align="right">{ ( 1 * (configuration.labor || 100)).toFixed(2) }$</td>
        </tr>)

    
        charges.push(<tr key="parts">
            <td>1</td>
            <td>Pièces</td>
            <td align="right">{ (1 * (configuration.parts || 30)).toFixed(2) }$</td>
            <td align="right">{ (1 * (configuration.parts || 30)).toFixed(2) }$</td>
        </tr>)

        if (order.job && order.job.qualifiers && order.job.qualifiers.indexOf('LDWS') !== -1) {
            total += configuration.ldws || 165
            clientTotal += configuration.ldws || 165
        
            charges.push(<tr key="ldws">
                <td>1</td>
                <td>Calibration LDWS</td>
                <td align="right">{ (1 * (configuration.ldws || 165)).toFixed(2) }$</td>
                <td align="right">{ (1 * (configuration.ldws || 165)).toFixed(2) }$</td>
            </tr>)
        }

        if (order.job && order.job.qualifiers && order.job.qualifiers.indexOf('Rain Sensor') !== -1) {
            total += configuration.rainSensor || 165
            clientTotal += configuration.rainSensor || 165
        
            charges.push(<tr key="rainSensor">
                <td>1</td>
                <td>Calibration Rain Sensor</td>
                <td align="right">{(1 * (configuration.rainSensor || 165)).toFixed(2) }$</td>
                <td align="right">{ (1 * (configuration.rainSensor || 165)).toFixed(2) }$</td>
            </tr>)
        }

        
        charges.push(<tr key="total">
            <td>&nbsp;</td>
            <td><strong>Total</strong></td>
            <td align="right"><strong>{ (1 * total).toFixed(2) }$</strong></td>
            <td align="right"><strong>{ (1 * clientTotal).toFixed(2) }$</strong></td>
        </tr>)


        return <div key="estimate">
            <h2>Estimé final</h2>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Qté.</th>
                        <th>Description</th>
                        <th align="right">Prix coûtant</th>
                        <th align="right">Prix client</th>
                    
                    </tr>
                </thead>
                <tbody>
                    { charges }
                </tbody>
            </table>
        </div>
        
    }

    
    renderDetails() {
        const { order, configuration } = this.state
        const { details = {}} = order

        const contactInformation = details.existing ? <tr>
            <td colSpan="2"><strong>Information à la caisse</strong></td>
        </tr>:<>
            <tr>
                <td align="right"><strong>Courriel</strong>&nbsp;</td>
                <td>{ details.email || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Téléphone</strong>&nbsp;</td>
                <td>{ details.phone || 'N/A' }</td>
            </tr>
        </>

        const insuranceInformation = details.insured ? <>
            <tr>
                <td align="right"><strong>NIV</strong>&nbsp;</td>
                <td>{ details.vin || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong># Permis de conduire</strong>&nbsp;</td>
                <td>{ details.license || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Assureur</strong>&nbsp;</td>
                <td>{ details.insurer || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Numéro de police</strong>&nbsp;</td>
                <td>{ details.policy || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Expiration</strong>&nbsp;</td>
                <td>{ details.expiration || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Endroit du bris</strong>&nbsp;</td>
                <td>{ details.breakLocation || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Type de bris</strong>&nbsp;</td>
                <td>{ details.breakType || 'N/A' }</td>
            </tr>
            <tr>
                <td align="right"><strong>Date du bris</strong>&nbsp;</td>
                <td>{ details.breakTime || 'N/A' }</td>
            </tr>
        </> : ( details.insured === false ? <tr><td colSpan="2"><strong>Le travail n'est pas assuré</strong></td></tr> : null)
        
        return <>
            <tr>
                <td align="right"><strong>Nom</strong>&nbsp;</td>
                <td>{ details.name || 'N/A' }</td>
            </tr>

            { contactInformation }
            { insuranceInformation }
        </>
    
    }

    renderMobileSummary() {
        const { order = {} } = this.state

        const { mobile = {} } = order

        const { name: mobileName } = mobile.mobile || {}
        
        const scheduledKey = mobile.scheduledDate ? `${ mobile.scheduledDate.datePart } ${ mobile.scheduledDate.timePart }` : null
        
        return <div key="mobile">
            <h2>Information mobile</h2>
            <table className="table table-responsive">
                <tbody>
                    <tr>
                        <td align="right"><strong>Mobile</strong></td>
                        <td>{ mobileName || 'N/A' }</td>
                    </tr>
                    {
                        mobile.scheduledKey ? <tr>
                        <td align="right"><strong>Date cédulée</strong></td>
                        <td>{ <span>{ Application.date(scheduledKey) } ({ Application.since(scheduledKey) })</span>  }</td>
                        </tr> : null
                    }
                    {
                        mobile.rejectedDates && mobile.rejectedDates.length > 0 ? <tr>
                            <td align="right"><strong>Dates rejetées</strong></td>
                            <td><span className="text-danger">
                                { mobile.rejectedDates.map(d => { 
                                    const key = `${ d.datePart } ${ d.timePart }`
                                    return <div key={ key }>{ Application.date(key) } ({ Application.since(key) })</div> 
                                })}
                                </span></td>
                        </tr>:null
                    }
                    {
                        mobile.dates && mobile.dates.length > 0 ? <tr>
                            <td align="right"><strong>Dates proposées</strong></td>
                            <td><span className="text-info">
                                { mobile.dates.map(d => { 
                                    const key = `${ d.datePart } ${ d.timePart }`
                                    return <div key={ key }>{ Application.date(key) } ({ Application.since(key) })</div> 
                                })}
                                </span></td>
                        </tr>:null
                    }
                    
                </tbody>
            </table>
        </div>   
    }


}
