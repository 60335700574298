import React from 'react'
import { Application } from '../../../Application'

export class SelectOpening extends React.Component {
    constructor(props) {
        super(props)
        this.state = { openings: null }
    }

    async componentDidMount() {
        const { openings = []} = await Application.get({ path: '/glass-order/openings', query: { vehicle: this.props.vehicle.id }})

        this.setState({ openings })
    }


    render() {
        if (!this.state.openings)
            return <div className="waiting"></div>
        /*{ openingConfiguration.side ? ` - ${ openingConfiguration.side.qualifier.value }` : '' }
            { openingConfiguration.position ? ` - ${ openingConfiguration.position.qualifier.value }` : '' }*/
        const items = this.state.openings.map((openingConfiguration, index) => {
            const opening = openingConfiguration.opening.description
            const side = openingConfiguration.side ? (openingConfiguration.side.qualifier ? openingConfiguration.side.qualifier.value : openingConfiguration.side.name) : null
            const position = openingConfiguration.position ? (openingConfiguration.position.qualifier ? openingConfiguration.position.qualifier.value : openingConfiguration.position.name) :null
            return <li className="list-group-item" key={ index }>
                { opening } { side } { position }
                
                <button className="btn btn-success pull-right" onClick={ () => this.props.onOpeningSelected(openingConfiguration)}>Choisir</button>
                <div className="clearfix"></div>
            </li> 
        })

        return <ul className="list-group">{ items }</ul>
    }
}

