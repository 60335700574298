import React from 'react'
import { TextEdit } from '../../../editors/text'
import { wheelSortFunction } from './summary/wheel-sort'

const defaultState = { diameter: '', width: '', originalWidth: '', minOffset: '', maxOffset: '', tire: '' }
export class WheelsEditor extends React.Component {
    constructor(props) {
        super(props)

        const state = { ... defaultState, editing: false }
        state.validation = this.validate(state)

        this.state = state

    }

    reset() {
        const state = { ... defaultState, editing: false }
        state.validation = this.validate(state)

        this.setState(state)
    }

    extract(state = {}) {
        state = { ... this.state, ... state }
        
        const diameter = Number.parseInt(state.diameter)
        const width = Number.parseFloat(state.width)
        const originalWidth = Number.parseFloat(state.originalWidth)
        const minOffset = Number.parseFloat(state.minOffset)
        const maxOffset = Number.parseFloat(state.maxOffset)
        const tire = state.tire || ''

        return { diameter, width, minOffset, maxOffset, tire, originalWidth }
        
    }

    validate(state) {
        const wheel = this.extract(state)
        const validation = { errors: {}, warnings: {}}

        const { diameter, width, originalWidth, minOffset, maxOffset } = wheel

        if (Number.isNaN(diameter) || diameter < 10)
            validation.errors.diameter = `Diamètre requis`

        if (Number.isNaN(width) || width < 5)
            validation.errors.width = `Largeur maximale requise`
        
        if (Number.isNaN(originalWidth) || originalWidth < 5)
            validation.errors.originalWidth = `Largeur d'origine requise`

        if (Number.isNaN(minOffset))
            validation.errors.minOffset = `Offset minimum requis`
        
        if (Number.isNaN(maxOffset))
            validation.errors.maxOffset = `Offset maximum requis`
        
        return validation
        
    }
    
    handleChange(field, value) {
        const state = {}
        state[field] = value
        state.validation = this.validate(state)        

        this.setState(state)
    }

    handleDelete(wheel) {
        const wheels = this.props.wheels.filter(wheel2 => wheel2.diameter !== wheel.diameter 
                || wheel2.width !== wheel.width || wheel2.originalWidth !== wheel.originalWidth)

        this.props.onChanged(wheels)
    }

    handleEdit(wheel) {
        this.setState({ ... wheel, validation: this.validate(wheel), editing: true })
    }

    handleSave = () => {
        const wheel = this.extract()

        const wheels = this.props.wheels
            .filter(wheel2 => wheel2.diameter !== wheel.diameter || wheel2.width !== wheel.width 
                || wheel2.originalWidth !== wheel.originalWidth)
        
        wheels.push(wheel)

        this.reset()

        this.props.onChanged(wheels)
    }

    render() { 
        
        const { diameter, width, originalWidth, minOffset, maxOffset, tire, validation, editing } = this.state
        const { errors, warnings } = validation

        const valid = Object.keys(errors).length === 0

        const { wheels = [] } = this.props

        wheels.sort(wheelSortFunction)

        const items = wheels.map((wheel, index) => <tr key={ index }>
            <td>{ wheel.diameter }</td>
            <td>{ wheel.originalWidth }</td>
            <td>{ wheel.minOffset }</td>
            <td>{ wheel.width }</td>
            <td>{ wheel.maxOffset }</td>
            <td>{ wheel.tire }</td>
            <td align="right">
                <button className="btn btn-xs btn-danger" onClick={ () => this.handleDelete(wheel) }>
                    <i className="fa fa-close"></i>
                </button>
                &nbsp;
                <button className="btn btn-xs btn-primary" onClick={ () => this.handleEdit(wheel) }>
                    <i className="fa fa-pencil"></i>
                </button>
            </td>
        </tr>)

        return <div>
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th rowSpan="2">Diamètre</th>
                        <th colSpan="2">Origine</th>
                        <th colSpan="2">Maximum recommandé</th>
                        <th rowSpan="2">Taille maximale<br/>des pneus</th>
                        <th rowSpan="2" className="text-right">Actions</th>
                    </tr>
                    <tr>
                        <th>Largeur</th>
                        <th>Offset</th>
                        <th>Largeur</th>
                        <th>Offset</th>
                    </tr>
                </thead>
                <tbody>
                    { items.length > 0 ? items : <tr><td colSpan="7" className="text-info">Cette modification n'a pas encore de taille de roue</td></tr> }
                    { editing ? 
                    <tr>
                        <td><TextEdit value={ diameter } placeholder="Diamètre" error={ errors.diameter } 
                            onChange={ value => this.handleChange('diameter', value) } /></td>
                        <td><TextEdit value={ originalWidth } placeholder="Largeur d'origine" error={ errors.originalWidth }  
                            onChange={ value => this.handleChange('originalWidth', value) } /></td>
                        <td><TextEdit value={ minOffset } placeholder="Off. min." error={ errors.minOffset }  
                            onChange={ value => this.handleChange('minOffset', value) } /></td>    
                        <td><TextEdit value={ width } placeholder="Largeur max." error={ errors.width }  
                            onChange={ value => this.handleChange('width', value) } /></td>
                        <td><TextEdit value={ maxOffset } placeholder="Off. max." error={ errors.maxOffset }  
                            onChange={ value => this.handleChange('maxOffset', value) } /></td>
                        <td><TextEdit value={ tire } placeholder="Pneus" error={ errors.tire }  
                            onChange={ value => this.handleChange('tire', value) } /></td>
                        <td>
                            <button className="btn btn-success btn-xs" onClick={ this.handleSave } disabled={ !valid }> 
                                <i className="fa fa-check"></i>
                            </button>
                        </td>
                    </tr>: <tr className="info">
                        <td colSpan="7">
                            <button className="btn btn-info" style={{ display: 'block', width: '100%' }} onClick={ () => this.setState({ editing: true })}>Ajouter une taille de roue</button>
                        </td>
                    </tr> }
                </tbody>
            </table>
        </div>
    }
}
