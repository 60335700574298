import React from 'react'

export class Select extends React.Component {
    
    constructor (props) {
        super(props)
    }

    render() {
        const list = (this.props.list || []).map((item, index) => 
            <li key={ index }><a onClick={ () => this.props.onChange(item) }>{ item }</a></li>)
        const value = this.props.value || this.props.caption

        return <div className="dropdown">
        <button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"  style={ this.props.controlStyle || null }>
        { value }
        &nbsp;
        <span className="caret"></span>
        </button>
        <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
            { (this.props.value && this.props.caption) ? <li className="dropdown-header">{ this.props.caption }</li> : null }
            { list }
        </ul>
    </div>
    }        
}
