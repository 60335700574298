import React from 'react'
import { Application } from '../../../Application'

class SelectGlassButton extends React.Component {
    constructor(props) {
        super(props)

        const item = props.item
        const key = `${ item.prefix.code }${ item.number.toString().padStart(6, '0') }${ item.color }${ item.attachment ? 'Y': 'N'}`
        
        const { provider = 'PGW', price = '' } = props.pricing && props.pricing[key] ? props.pricing[key] : {}

        this.state = { key, provider, price, selecting: false }
    }

    handleChange = (event) => {
        this.setState({ price: event.target.value })
    }

    renderProviderLink(provider) {
        switch (provider) {
            case 'PGW': return <a href="https://www.buypgwautoglass.com/" target="_blank">Visiter PGW</a>
            case 'PH': return <a href="https://www.ph.ca/products/pare-brise-2/" target="_blank">Visiter PH</a>
            case 'Vanfax': return <a href="https://www.vanfax.com/fr/connexion" target="_blank">Visiter Vanfax</a>
        }

        return null
    }

    render() {
        const { provider, price, selecting } = this.state
        const providers = ['PGW', 'PH', 'Vanfax']

        
        const floatValue = Number.parseFloat(price)
        const isANumber = !Number.isNaN(floatValue)
        const estimate = isANumber && price && this.props.markup ? <div className="text-success">+{ this.props.markup }% = { ((floatValue * (100 + this.props.markup)) / 100).toFixed(2) }$</div> : null
        
        if (!selecting) {
            const caption = price && provider ? 
                <span className="text-success">{ provider }: { isANumber ? floatValue.toFixed(2) : null }$ <br/>{ estimate }</span> :
                (this.props.msrp ? <span className='text-muted'>~ { this.props.msrp.toFixed(2) }$</span> : null)
            return <div>
                { caption }
                &nbsp;
                <button className="btn btn-primary" type="button" onClick={ () => this.setState({ selecting: true })}>Choisir</button>
            </div>
        }

        const items = providers.map(item => <li key={ item }><a onClick={ () => this.setState({ provider: item }) }>{ item }</a></li>)

        const link = this.renderProviderLink(provider)

        const button = price && provider && estimate ? 
            <button className="btn btn-primary" type="button" onClick={ () => this.props.onGlassSelected(this.props.item, this.state.key, provider, floatValue) }>Choisir</button> :
            <button className="btn btn-danger" type="button" onClick={ () => this.setState({ selecting: false })}>
                <i className="fa fa-close"/>
            </button>

        return <div>
            <div className="input-group">
                <div className="input-group-btn">
                    <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        { provider }
                        &nbsp;
                        <span className="caret"></span>
                    </button>
                    <ul className="dropdown-menu">
                        { items }
                    </ul>
            </div>
            <input type="text" className="form-control" aria-label="Prix" value={ price } onChange={ this.handleChange } />
            <span className="input-group-btn">
                { button }
            </span>
        </div>
        { estimate || link }
      </div>

    }
}



export class SelectGlass extends React.Component {
    constructor(props) {
        super(props)
        this.state = { glasses: null }
    }
    
    async componentDidMount() {
        const job = this.props.opening

        const vehicle = this.props.vehicle.id
        const opening = job.opening.code
        const side = (job.side || {}).code
        const position = (job.position || {}).code
        const color = (job.color || {}).code

        const query = { vehicle, opening, side, position, color}

        const { glasses = []} = await Application.get({ path: '/glass-order/glasses', query})

        const { qualifiers } = this.props

        if (qualifiers && qualifiers.length > 0) {
            for (const glass of glasses) {
                glass.presentQualifiers = []
                glass.missingQualifiers = []

                for (const qualifier of qualifiers) {
                    if (glass.qualifiers.find(q => q.value === qualifier))
                        glass.presentQualifiers.push(qualifier)
                    else 
                        glass.missingQualifiers.push(qualifier)
                }
            }

            glasses.sort((a, b) => {
                if (a.missingQualifiers.length < b.missingQualifiers.length) return -1
                if (b.missingQualifiers.length < a.missingQualifiers.length) return 1

                if (a.pricing && !b.pricing) return -1
                if (!a.pricing && b.pricing) return 1
                if (!a.pricing && !b.pricing) return 0

                if (a.pricing.price && !b.pricing.price) return -1
                if (!a.pricing.price && b.pricing.price) return 1

                return a.pricing.price < b.pricing.price ? -1 : 1
            })
        } else {
            
            glasses.sort((a, b) => {
                
                return a.qualifiers.length < b.qualifiers.length ? 1 : -11
            })
        }

        this.setState({ glasses })
    }

    handleGlassSelection = (item, key, provider, price) => {
        if (item.missingQualifiers && item.missingQualifiers.length > 0) {
            const useAnyways = confirm('Ce verre n\'a pas toutes les caractéristiques souhaitées, voulez-vous continuer quand même?')
            if (!useAnyways) return
        }

        this.props.onGlassSelected(item, key, provider, price)
    }

    render() {
        const { glasses } = this.state

        
        if (!glasses) 
            return <div className="waiting"></div>

        const glassSelection = glasses.map((item, index) => {
            const price = item.pricing && item.pricing.price > 0 ? item.pricing.price : null
            const availability = item.pricing && item.pricing.availability && item.pricing.availability.name ? <div className='text-muted'>Disponible</div>: null
            const key = `${ item.prefix.code }${ item.number.toString().padStart(6, '0') }${ item.color }${ item.attachment ? 'Y': 'N'}`
            return <tr key={ key } className={ item.missingQualifiers && item.missingQualifiers.length > 0 ? 'danger' : 'active' }>
                <td>
                    <strong>{ key }</strong>
                    { availability }
                </td>
                <td>
                    { item.qualifiers && item.qualifiers.length > 0 ? <strong>{ item.qualifiers.map(q => q.value).join(', ') }</strong> : null }
                    { item.missingQualifiers && item.missingQualifiers.length > 0 ? <div><strong className="text-danger">Caractéristiques manquantes: { item.missingQualifiers.join(', ') }</strong></div> : null }
                </td>
                <td align="right">
                    
                    <SelectGlassButton onGlassSelected={ this.handleGlassSelection } item={ item } pricing={ this.props.pricing }  msrp={ price } markup={ this.props.markup } />
                    
                </td>
            </tr>})
        

        return <table className="table table-responsive">
                <thead>
                    <tr>
                        <td>
                            Numéro de pièce
                        </td>
                        <td>
                            Caractéristiques et accessoires
                        </td>
                        <td align="right">Prix et sélection</td>
                    </tr>
                </thead>
                <tbody>
                    { glassSelection }
                </tbody>
            </table>

    }
}
