import React from 'react'
import { Application } from '../../Application'
import * as changeCase from 'change-case'

class CreateServiceForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = { value: '', error: null }
    }

    handleChange = event => {
        this.setState({ value: event.target.value })
    }

    handleSubmit = async event => {
        event.preventDefault()

        const { value } = this.state
        if (value.length < 3) {
            this.setState({ error: 'Le nom est trop court' })
            return
        }

        if (changeCase.paramCase(value) !== value) {
            this.setState({ error: 'Le nom est invalide (minuscules et trait d\'union seulement)'})
            return
        }
            
        const result = await Application.post({ path: '/service/create', data: { value }})

        if (!result.success) {
            this.setState({ error: result.error })
            return
        }

        const url = Application.url({ path: '/service/update', id: result.service })
        location = url
        
    }


    render() {
        const hasError = Boolean(this.props.error)
        const errorLabel =  hasError ? <div className="text-danger">{ this.props.error }</div> : null

        return (
            <div className="alert alert-danger">
                <h5>Créer un service</h5>
                <form onSubmit={ this.handleSubmit }>
                    <div className="input-group">
                        <input 
                            className="form-control" 
                            type="text" 
                            placeholder="Nom interne" 
                            onChange={ this.handleChange } 
                            value={ this.state.value } 
                        />
                        <span className="input-group-btn">
                            <button className="btn btn-default" type="submit" disabled={ this.state.submitting }>Créer</button>
                        </span>
                    </div>
                    { errorLabel }
                </form>
            </div>
        )
    }
}

Application.registerComponent(CreateServiceForm, 'portal.service.create')