import React from 'react'
import { Application } from '../../../Application'
import { GlassOrderView } from './view'
import { GlassOrderQuote } from './quote'

export class GlassOrderEdit extends React.Component {
    constructor(props) {
        super(props)

        this.state = { order: null, configuration: null, status: null }
    }

    async refresh() {
        const { order, configuration, status } = await Application.get({ path: '/glass-order/list', query: { order: this.props.order }})
        this.setState({ order, configuration, status })
    }

    
    async componentDidMount() {
        this.refresh()
        
    }
    
    handleModified = async () => {
        this.refresh()
    }

    render() {
        const { order, status, configuration } = this.state

        if (!order)
            return <div className="waiting"></div>
        
        let editor 
        switch (status) {
            case 'quote':
            case 'draft':
                editor = <GlassOrderQuote id={ this.props.order } order={ order } configuration={ configuration }  onClosed={ () => this.props.onOrderClosed() }/>
                break
            case 'work':
            case 'complete':
            case 'canceled':
            case 'schedule':
            case 'scheduled':
            default:                
                editor = <GlassOrderView id={ this.props.order } order={ order } status={ status } configuration={ configuration } onModified={ this.handleModified } onClosed={ () => this.props.onOrderClosed() }/>
            
        }

        return <div style= {{ minHeight: '500px' }}>
            <h2>Commande { this.props.order.toString().padStart(6, '0') }
                <button className="btn btn-primary pull-right" onClick={ () => this.props.onOrderClosed() }>
                    <i className="fa fa-caret-left"></i>
                    &nbsp;
                    Retour
                </button>
            </h2>
            <div className="clearfix"></div>
            <hr/>
            { editor }
        </div>
        

    }

}