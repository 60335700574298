import React from 'react'
import moment from 'moment'

export class DateEdit extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { error, warning, label = '', value, onChange = () => {} } = this.props
        const input = <input type="date" className="form-control" value={ moment(value).format('YYYY-MM-DD') } 
            onChange={ event => onChange(event.target.value) } />

        const errorMessage = error ? <div className="text-danger">{ error }</div> : null
        const warningMessage = warning ? <div className="text-warning">{ warning }</div> : null

        return <div className="form-group">
                <label>{ label }</label>
                { input }
                { errorMessage }
                { warningMessage }
            </div>
    }
}