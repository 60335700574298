import React from 'react'
import { Application } from '../../Application'
import { Select } from '../../editors/select'
import { ConsumerKitSummary } from './kit/summary/consumer'


class VehicleSuspensionConsumer extends React.Component {

    constructor(props) {
        super(props)

        this.state = { makes: null, models: null, years: null, modifications: null, 
            make: null, model: null, year: null, modification: null }

    }

    async componentDidMount() {
        const { makes, configuration } = await Application.get({ path: '/suspension-kit/makes' })

        if (configuration?.labor && typeof configuration.labor === 'string') {
            configuration.labor = Number.parseFloat(configuration.labor)
        }

        if (configuration?.alignment && typeof configuration.alignment === 'string') {
            configuration.alignment = Number.parseFloat(configuration.alignment)
        }

        makes.sort()

        this.setState({ makes, configuration })
    }

    handleMakeChange = async make => {
        const { models } = await Application.get({ path: '/suspension-kit/models', query: { vehicleMake: make }})

        models.sort()

        this.setState({ models, make, modifications: null, model: null, year: null, modification: null })
    }

    handleModelChange = async model => {
        const { make } = this.state
        const { years } = await Application.get({ path: '/suspension-kit/years', query: { vehicleMake: make, vehicleModel: model }})

        years.sort((a, b) => a < b ? 1 : -1)
        
        this.setState({ years, model, modifications: null, year: null, modification: null })

    }

    handleYearChange = async year => {
        const { make, model } = this.state
        const { modifications } = await Application.get({ path: '/suspension-kit/modifications', query: { vehicleMake: make, vehicleModel: model, vehicleYear: year }})

        this.setState({ modifications, year, modification: null })
    }

    render() {
        const { makes, models, years, make, model, year } = this.state

        if (!makes) 
            return <div className="waiting"></div>

       
        return <div style={{ minHeight: '400px' }}>
            <div className="row">
                <div className="col-xs-12">
                    <h2>Sélectionnez le véhicule à modifier</h2>
                </div>
                <div className="col-xs-12 col-sm-4">
                    <strong>Marque</strong>
                    <Select list={ makes } value={ make } caption="Choisir la marque" onChange={ this.handleMakeChange } />
                </div>
                <div className="col-xs-12 col-sm-4">
                    <strong>Modèle</strong>
                    { make ?
                    <Select list={ models } value={ model } caption="Choisir le modèle" onChange={ this.handleModelChange } />
                    : null }
                </div>
                <div className="col-xs-12 col-sm-4">
                    <strong>Année</strong>
                    { make && model ? 
                    <Select list={ years } value={ year } caption="Choisir l'année" onChange={ this.handleYearChange } />
                    : null }
                </div>
                <div className="col-xs-12">
                    
                    { this.renderModifications() }
                </div>
            </div>
            
        </div>
    }

    handleToggle = kit => {
        this.setState({ modification: this.state.modification && this.state.modification.id === kit.id ? null : kit })
    }

    renderModifications() {
        const { modifications, make, model, year, modification, configuration } = this.state
        
        if (modifications === null) return null

        if (modifications.length === 0)
            return <div className="alert alert-success">Aucune modification n'a été trouvée</div>

        const list = modifications.map((kit, index) =>  
            <ConsumerKitSummary key={ index } kit={ kit } configuration={ configuration } 
            open={ modification && modification.id === kit.id } onToggle={ () => this.handleToggle(kit) } />)

        return <div>
            <hr/>
            <h2>
                Modifications pour <br/>
                <strong>{ make } { model } { year }</strong>
            </h2>

            <table className="table table-responsive">
                <thead>
                    <tr>
                        <td>Modification</td>
                        <td>Marque</td>
                        <td>Prix client</td>
                    </tr>
                </thead>
                <tbody>
                    { list }
                </tbody>
            </table>
        </div>
    }
}

Application.registerComponent(VehicleSuspensionConsumer, 'vehicle.suspension.consumer')
