import React from 'react'

const defaultState = { editing: false, quantity: 1, price: '', part: '', description: '' }


export class SelectOtherParts extends React.Component {
    constructor(props) {
        super(props)

        const state = { ...defaultState }

        state.validation = this.validate(state)

        this.state = state
    }

    parse(state) {
        state = state || this.state

        const { quantity: quantityString = '', part = '', description = '', price: priceString = '' } = state
        
        const parsedQuantity = Number.parseInt(quantityString)
        const parsedPrice = Number.parseFloat(priceString)
        const quantity = !Number.isNaN(parsedQuantity) ? parsedQuantity : 1
        const price = priceString !== '' && !Number.isNaN(parsedPrice) ? parsedPrice : null

        return { quantity, part, description, price }
    }

    validate(state = null) {
        const { quantity = Number.NaN, part = '', price } = this.parse(state)
        const validation = {}

        if (!quantity || Number.isNaN(quantity) || quantity < 1) 
            validation.quantity = 'Quantité requise'

        if (price === null || Number.isNaN(price))
            validation.price = 'Prix requis'

        if (part.length < 3)
            validation.part = 'Numéro de pièce requis'
        
        return validation
    }

    handleChange = event => {
        const { name, value } = event.target
        const state = {}
        state[name] = value

        if (name === 'price' && value.length > 0) {
            const parsedFloat = Number.parseFloat(value)

            if (Number.isNaN(parsedFloat)) return
        }

        const validation = this.validate({ ... this.state, ... state})
        state.validation = validation

        this.setState(state)
        
    }


    renderInput(field, value, placeholder, type='text') {
        const validation = (this.state.validation || {})[field]

        return <>
                <input type={ type } name={ field } value={ value } onChange={ this.handleChange } 
                className="form-control" placeholder={ placeholder } />
                { validation ? <div className="text-danger">{ validation }</div>: null }
            </>

    }

    handleEdit = part => {
        this.setState({ ...part, editing: true })
    }

    handleCancel = () => {
        this.setState({ ...defaultState })
    }

    handleSave = () => {
        let { parts = [] } = this.props
        const part = this.parse()

        parts = parts.filter(p => p.part !== part.part)

        parts.push(part)

        this.setState({ ...defaultState, editing: true })

        this.props.onChanged(parts)

    }

    handleRemove = part => {
        let { parts = [] } = this.props

        parts = parts.filter(p => p.part !== part.part)

        this.props.onChanged(parts)
    }
     
    render() {

        const { quantity, part, description, price, editing, validation } = this.state

        const valid = Object.keys(validation).length === 0
        
        const editor = editing ? <tr className="active">
            
            <td colSpan="2">
                { this.renderInput('quantity', quantity, 'Quantité', 'number') }
            </td>
            <td>
                { this.renderInput('part', part, 'Numéro de pièce') }
            </td>
            <td>
                { this.renderInput('description', description, 'Description') }
            </td>
            <td align="right">
                { this.renderInput('price', price, 'Prix') }
                <button className="btn btn-danger btn-xs" onClick={ this.handleCancel }>
                    <i className="fa fa-close"></i>
                </button>
                &nbsp;
                <button className="btn btn-success btn-xs" onClick={ this.handleSave } disabled={ !valid }>
                    <i className="fa fa-check"></i>
                </button>
            </td>
        </tr>:<tr className="info">
            <td colSpan="5" align="center">
                <a onClick={ () => this.setState({ editing: true }) }>
                    Cliquez ici pour ajouter une pièce à la liste manuellement
                </a>
            </td>
        </tr>

        const { parts = [] } = this.props
        
        const list = parts.map((part, index) => {
            return <tr key={ index }>
                <td colSpan="2">{ part.quantity }</td>
                <td>{ part.part }</td>
                <td>{ part.description }</td>
                <td align="right">
                    { part.price.toFixed(2) }$

                    <div className="pull-right">
                        <button className="btn btn-danger btn-xs" onClick={ () => this.handleRemove(part) }>
                            <i className="fa fa-close"></i>
                        </button>
                        &nbsp;
                        <button className="btn btn-info btn-xs" onClick={ () => this.handleEdit(part) }>
                            <i className="fa fa-pencil"></i>
                        </button>
                    </div>
                </td>
            </tr>
        })
    
        return <>
            { list }
            { editor }
        </>
      
    }
 }

 //{ acceptButton }&nbsp;{ cancelButton }