import React from 'react'
import { Application } from '../../Application'

import { KitList } from './kit/list'
import { KitEdit } from './kit/edit'

const title = 'GPS Suspension (Gestion)'

const kitSorter = (a, b) => {
    if (a.vehicleMake < b.vehicleMake) return -1
    if (b.vehicleMake < a.vehicleMake) return 1

    if (a.vehicleModel < b.vehicleModel) return -1
    if (b.vehicleModel < a.vehicleModel) return 1

    return 0
}

class VehicleSuspensionManager extends React.Component {

    constructor(props) {
        super(props)

        this.state = { kits: null, view: 'list', draft: {}}

    }

    async refresh() {
        const { kits } = await Application.get({ path: '/suspension-kit/kits' })

        kits.sort(kitSorter)

        this.setState({ kits })
    }

    async componentDidMount() {
        await this.refresh()
    }

    handleAddKit = () => {
        this.setState({ view: 'add' })
    }

    handleEditKit = kit => {
        this.setState({ view: 'edit', editing: kit })
    }

    handleCancelEdit = () => {
        this.setState({ view: 'list' })
    }

    handleKitAdded = async kit => {
        
        const { result } = await Application.post({ path: '/suspension-kit/save', data: { kit: JSON.stringify(kit) }})

        this.setState({ view: 'list' })

        await this.refresh()
    }

    handleKitSaved = async (kit, data) => {
        const { result } = await Application.post({ path: '/suspension-kit/save', data: { id: kit.id, kit: JSON.stringify(data) }})

        this.setState({ view: 'list' })

        await this.refresh()
    }

    handleDelete = async id => {
        if (!confirm('Êtes-vous certain de vouloir supprimer ce kit?')) return

        const { result } = await Application.post({ path: '/suspension-kit/delete', data: { kit: id }})

        await this.refresh()
    }

    render() {
        
        const { kits, view: viewName = 'list', editing, draft = {} } = this.state

        let view = null
        switch (viewName) {
            case 'list':
                view = <KitList kits={ kits } onAdd={ this.handleAddKit } onEdit={ this.handleEditKit } onDelete={ this.handleDelete }/>
                break
            case 'add':
                view = <KitEdit title="Ajouter un kit" kit={ draft } kits={ kits } 
                    onCancel={ this.handleCancelEdit } onSave={ this.handleKitAdded } />
                break
            case 'edit': 
                view = <KitEdit title="Modifier un kit" kit={ editing } kits={ kits } 
                    onCancel={ this.handleCancelEdit } onSave={ kit => this.handleKitSaved(editing, kit) } />
                break
            default: 
                view = <div className="alert alert-danger">Unknown state</div>
                break
        }

        return <div>
            <h1>{ title }</h1>
            { view }
            <div className="clearfix"><br/></div>
        </div>

    }

    
}

Application.registerComponent(VehicleSuspensionManager, 'vehicle.suspension.manager')
