import ReactDOM from 'react-dom'
import React from 'react'
import moment from 'moment'

moment.locale('fr')

let applicationInstance = null

export class Application {
    constructor() {
        this._components = new Map()

        const { dataset } = document.documentElement
        
        this._root = dataset.root
        this._bucket = Number(dataset.bucket)
    }

    get root() {
        return this._root
    }

    urlInternal({ root = this.root, path, id, query }) {
        const searchParameters = new URLSearchParams()
        const keys = query ? Object.keys(query) : []
        for (const key of keys)
            searchParameters.append(key, query[key])

        return `${ root }${ path }${ id ? `/${ id }` : ''}?${ keys.length > 0 ? searchParameters.toString() : '' }`
    }

    static url(options) {
        return Application.instance.urlInternal(options)
    }

    static async fetch(options) {
        const url = Application.url(options)

        const fetchOptions = { ... options }
        fetchOptions.redirect = 'follow'
        fetchOptions.referrerPolicy = 'no-referrer'
        

        const response = await fetch(url, fetchOptions)
        
        if (response.ok) 
            return response.json()
        
        throw new Error(`post error (${ url }) ${ JSON.stringify(response) }`)
    }

    static async get(options) {
        const fetchOptions = { ... options }
        
        fetchOptions.method = 'get'
        
        return Application.fetch(fetchOptions)
    }

    static async post(options) { // { root, path, id, query, data }
        const fetchOptions = { ... options }

        fetchOptions.method = 'post'
        
        const formData = new FormData()
        const keys = options.data ? Object.keys(options.data) : []
        for (const key of keys)
            formData.append(key, options.data[key])

        fetchOptions.body = formData
        
        
        return await Application.fetch(fetchOptions)
    }

    hasComponentInternal(path) { return this._components.has(path) }

    static hasComponent(path) { return Application.instance.hasComponentInternal(path) }

    getComponentInternal(path) { return this.hasComponentInternal(path) ? this._components.get(path) : null }

    static getComponent(path) { return Application.instance.getComponentInternal(path) }

    static get instance() {
        if (!applicationInstance) 
            applicationInstance = new Application()

        return applicationInstance
    }

    registerComponentInternal(component, path) { 
        if (this.hasComponentInternal(path))
            throw new Error(`Component already registered: ${ path }`)

        this._components.set(path, component)
    }

    static registerComponent(component, path) {
        Application.instance.registerComponentInternal(component, path)
    }

    static refresh() {
        const componentNodes = document.querySelectorAll('div.gnak-component')
        componentNodes.forEach(node => {
            const path = node.dataset.component
            if (path && Application.hasComponent(path)) {
                const Component = Application.getComponent(path)
                ReactDOM.render(<Component />, node)
            } else 
                console.log(`Unknown component ${ path }`)
        })

    }

    static since(date) {
        return moment(date).fromNow()
    }

    static date(date) {
        return moment(date).format('LLLL')
    }

    static number(input) {
        if (typeof input === 'string') input = Number.parseFloat(input)

        if (typeof input === 'number') return input
                

    }
}