import React from 'react'
import { Application } from '../../../Application'
import { GlassOrderSharedSummary } from './shared-summary'


export class GlassOrderView extends React.Component {
    constructor(props) {
        super(props)
   
    }

    async changeStatus(status, reload = false) {
        await Application.get({ path: '/glass-order/status', query: { order: this.props.id, status }})

        if (reload)
            this.props.onModified()
        else 
            this.props.onClosed()
    }

    
    handleComplete = () => {
        if (confirm('Compléter cette commande?'))
            this.changeStatus('complete')    
    }

    handleCancel = () => {
        if (confirm('Voulez-vous annuler cette commande?'))
            this.changeStatus('canceled')
    }

    
    handleModify = () => {
        if (confirm('Voulez-vous modifier cette commande?'))
            this.changeStatus('quote', true)
    }

    render() {
        const { status } = this.props
        let caption
        switch (status) {
            
            case 'canceled':
                caption = <div className="alert alert-danger">
                    <strong>Cette commande a été annulée</strong>
                </div>
                break
            case 'complete':
                caption = <div className="alert alert-success">
                    <strong>Cette commande a été complétée</strong>
                </div>
                break
            case 'schedule':  
            caption = <div className="alert alert-info">
                    <strong>Demande de rendez-vous mobile</strong><br/>
                    L'agent mobile doit fournir des options pour la date du rendez-vous. Le statut de la commande changera lorsque les dates seront disponibles. Vous pouvez modifiez et resoumettre la commande, ou annulez la commande
                    <hr/>
                    <span className="pull-right">
                        <button className="btn btn-danger" onClick={ this.handleCancel }>
                            <i className="fa fa-close"></i>
                            &nbsp;
                            Annuler
                        </button>
                        &nbsp;
                        <button className="btn btn-info" onClick={ this.handleModify }>
                            <i className="fa fa-pencil"></i>
                            &nbsp;
                            Modifier
                        </button>
                    </span>
                    <div className="clearfix"></div>
                </div>
                break
            case 'scheduled':
            case 'work': 
                caption = <div className="alert alert-info">

                    <strong>Travaux en cours { status === 'scheduled' ? '(Mobile)' : null }</strong><br/>
                    { status === 'scheduled' ? `Le mobile va compléter la commande lorsque les travaux seront complétés. Vous pouvez modifier ou annuler la commande`: 
                        `Vous pouvez complétez la commande lorsque les travaux ont été effectués, modifiez la commande ou annulez la commande`
                    }
                    <hr/>
                    
                    <span className="pull-right">
                        <button className="btn btn-danger" onClick={ this.handleCancel }>
                            <i className="fa fa-close"></i>
                            &nbsp;
                            Annuler
                        </button>
                        &nbsp;
                        <button className="btn btn-info" onClick={ this.handleModify }>
                            <i className="fa fa-pencil"></i>
                            &nbsp;
                            Modifier
                        </button>
                        
                        &nbsp;
                        { status === 'work' ? <button className="btn btn-success" onClick={ this.handleComplete }>
                            <i className="fa fa-check"></i>
                            &nbsp;
                            Compléter
                        </button> : null }
                    </span>
                    <div className="clearfix"></div>
                </div>
        }

        return <div>
            { caption }
            <GlassOrderSharedSummary order={ this.props.order } configuration={ this.props.configuration } />
        </div>
    }

}
