import React from 'react'
import { ManagerKitSummary } from './summary/manager'

export class KitList extends React.Component {
    constructor(props) {
        super(props)

        this.state = { open: null }
    }

    toggle(id) {
        this.setState({ open: this.state.open === id ? null : id })
    }

    render() {
        const { kits } = this.props

        if (kits === null)
            return <div className="waiting"></div>

    
        if (kits.length === 0) 
            return <div className="alert alert-info">
                Aucun kit n'a été défini. Ajoutez le premier pour commencer
                <hr/>
                <button className="btn btn-info pull-right" onClick={ this.props.onAdd }>Ajouter</button>
                <div className="clearfix"></div>
            </div>

        const list = kits.map(kit => <ManagerKitSummary kit={ kit } key={ kit.id }  open={ this.state.open === kit.id }
            onEdit={ () => this.props.onEdit(kit) } onToggle={ () => this.toggle(kit.id) } onDelete={ () => this.props.onDelete(kit.id) } />)
    
        return <div>
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <td>Modification</td>
                            <td>Marque</td>
                            <td>Vehicules</td>
                            <td>Prix</td>
                            <td align="right"><button className="btn btn-primary btn-xs" onClick={ this.props.onAdd }>Ajouter</button></td>
                        </tr>
                    </thead>
                    <tbody>
                        { list }
                    </tbody>
            </table>
        </div>

    }
}
