import React from 'react'
import { Application } from '../../Application'

function StatusTabs() {
    return <ul className="nav nav-tabs">
        <li role="presentation" className="active"><a>Workers</a></li>
        <li role="presentation"><a>Errors</a></li>
        <li role="presentation"><a>404</a></li>
    </ul>
}

class PortalStatusHome extends React.Component {
    constructor(props) {
        super(props)

        this.state = { workers: null }
    }

    async componentDidMount() {
        const { workers } = await Application.post({ path: '/status/index' })

        this.setState({ workers })
    }

    render() {
        if (!this.state.workers) 
            return <div className="waiting"></div>

        const statusClass = worker => {
            switch(worker.status) {
                case 'OK': 
                    if (Date.now() - worker.latest > 300000) 
                        return 'danger'

                    return 'success'
                case 'Died': 
                    if (worker.exception && worker.exception.startsWith('Error: Pacemaker\n'))
                        return 'info'
                    return 'danger'
            }

            return 'active'
        }

        return <div>
            <StatusTabs />
            <table className="table table-responsive">
                <thead>
                    <tr>
                        <th>Rôle</th>
                        <th>Début</th>
                        <th>Dernier mise-à-jour</th>
                        <th>Requêtes</th>
                    </tr>
                </thead>
                <tbody>
                    { this.state.workers.map((worker, index) => <tr key={ index } className={ statusClass(worker) }>
                        <td>{ worker.role }<br/>
                            { worker.id }
                        </td>
                        <td>{ Application.since(worker.start) }</td>
                        <td>{ Application.since(worker.latest) }</td>
                        <td>{
                            worker.requests.length === 0 ? '0' : <div>
                                { worker.requests.length }
                            </div>
                        }</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    }
}

Application.registerComponent(PortalStatusHome, 'portal.status.home')