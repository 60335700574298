import React from 'react'
import { Application } from '../../../Application'

class DateList extends React.Component {
    constructor(props) {
        super(props)

        this.state = { dates: props.dates || [], newDate: '', newTime: '' }
    }

    handleDateChange = event => {
        this.setState({ newDate: event.target.value })
    }

    handleTimeChange = event => {
        this.setState({ newTime: event.target.value })
    }

    handleClick = event => {
        const dates = [ ... this.state.dates]
        const newDate = { datePart: this.state.newDate, timePart: this.state.newTime }

        const existing = dates.find(d => d.datePart === newDate.datePart && d.timePart === newDate.timePart)
        
        if (!existing) 
            dates.push(newDate)

        if (this.props.onDatesChanged)
            this.props.onDatesChanged(dates)
        
        this.setState({ dates, newDate: '', newTime: '' })
    }

    handleRemoveDate(date) {
        const dates = this.state.dates.filter(d => !(d.datePart === date.datePart && d.timePart === date.timePart))

        if (this.props.onDatesChanged)
            this.props.onDatesChanged(dates)

        this.setState({ dates })
    }

    render() {
        const dates = (this.state.dates || []).map(date => <div key={ `${ date.datePart } ${ date.timePart }` }>
            { Application.date(`${ date.datePart } ${ date.timePart }`) } ({ Application.since(`${ date.datePart } ${ date.timePart }`) })
            <a className="text-danger" onClick={ () => this.handleRemoveDate(date) }><i className="fa fa-close"/></a>
        </div>)

        return <div>
            { dates.length > 0 ? <div className="text-muted">Dates et heures</div>: null }
            { dates }
            { dates.length > 0 ? <hr/>: null }
            <div className="row">
                <div className="form-group col-xs-12 col-sm-5">
                    <label htmlFor="mobile-date">Date</label>
                    <input className="form-control" id="mobile-date" type="date" value={ this.state.newDate } onChange={ this.handleDateChange }/>
                </div>
                <div className="form-group col-xs-12 col-sm-5">
                    <label htmlFor="mobile-time">Heure</label>
                    <input className="form-control" id="mobile-time" type="time" value={ this.state.newTime } onChange={ this.handleTimeChange }/>
                </div>
                <div className="form-group col-xs-12 col-sm-2">
                    <div className="text-sucess">{ this.state.newDate && this.state.newTime ? Application.since(`${ this.state.newDate } ${ this.state.newTime}`) : <span>&nbsp;</span> }</div>
                    <button className="btn btn-primary" disabled={ !(this.state.newDate && this.state.newTime) } onClick={ this.handleClick }>Ajouter</button>
                </div>
                
            </div>
            
        </div>
    }
}


export class SelectMobile extends React.Component {
    constructor(props) {
        super(props)
        
        const { dates = [], mobile = null, notes = '', local = props.local } = props.order.mobile || {}
        this.state = { mobiles: null, dates, local, mobile, notes }
    }

    async componentDidMount() {
        const { mobiles = [] } = await Application.get({ path: '/glass-order/mobiles' })

        if (mobiles.length === 1 && !this.state.local) 
            this.setState({ mobiles, mobile: mobiles[0] })
        else
            this.setState({ mobiles })

    }

    handleMobileSelected = event => {
        if (!event.target.checked) return
        
        const { value } = event.target

        const parsedValue = Number.parseInt(value)

        if (value === 'local') 
            this.setState({ local: true, mobile: null })
        else 
            this.setState({ local: false, mobile: this.state.mobiles.find(m => m.id === parsedValue) })
    
    }

    onSubmit = () => {
        const { local, dates, notes, mobile } = this.state 

        this.props.onChange({ local, mobile, dates, notes })
    }

    render() {
        if (!this.state.mobiles)
            return <div className="waiting"></div>

        if (this.state.mobiles.length === 0)
            return <div className="alert alert-info">Configuration error</div>
        
        let mobileOptions = this.state.mobiles
                .map(mobile => <div className="radio" key={ mobile.id }>
                    <label>
                        <input type="radio" name="mobile" value={ mobile.id } checked={ this.state.mobile && this.state.mobile.id === mobile.id } onChange={ this.handleMobileSelected } />
                        { mobile.name }
                    </label>
            </div>)

        if (this.props.local) 
            mobileOptions.push(<div className="radio" key="local">
            <label>
                <input type="radio" name="mobile" value="local" onChange={ this.handleMobileSelected } checked={ this.state.local }/>
                Travaux à l'interne
            </label>
        </div>)
    
        return <div>
            { mobileOptions.length > 1 ? <div>
                <h4>Sélectionnez le mobile</h4>
                { mobileOptions }
            </div> : <div>
                <strong>{ this.state.mobiles[0].name }</strong>    
            </div>}
            
            {!this.state.local ? <div>
                <h4>Suggérez au moins 2 dates de rendez-vous</h4>
                { this.renderRejectedDates() }
                <DateList dates={ this.state.dates }  onDatesChanged={ dates => this.setState({ dates }) } />
                <div className="form-group">
                    <label>
                        Notes pour le mobile
                        <textarea className="form-control" rows="3" value={ this.state.notes } onChange={ event => this.setState({ notes: event.target.value })} />
                    </label>
                </div>
            </div> : null }
            
            { this.state.local || (this.state.dates && this.state.dates.length > 1 && this.state.mobile) ? 
                <div><button className="btn btn-primary pull-right" onClick={ this.onSubmit }>Accepter</button></div>
                : null
            }
        </div>
        
    }

    renderRejectedDates() {
        const { rejectedDates } = (this.props.order || {}).mobile || {}

        if (!rejectedDates) return null

        return <div className="alert alert-danger">
            <h4>Les dates suivantes ont été rejetées</h4>
            { rejectedDates.map((date, index) => {
                const key = `${ date.datePart } ${ date.timePart }`
                return <span key={ key }>{ index === 0 ? null : ', ' }{ Application.date(key) }</span>
            })}
        </div>

        
    }

}