import React from 'react'
import { Application } from '../../../Application'

export class SelectGlassColor extends React.Component {
    constructor(props) {
        super(props)
        this.state = { colors: null }
    }

    
    async componentDidMount() {
        const job = this.props.opening

        const vehicle = this.props.vehicle.id
        const opening = job.opening.code
        const side = (job.side || {}).code
        const position = (job.position || {}).code

        const query = { 
            vehicle, opening, side, position
        }

        const { colors = []} = await Application.get({ path: '/glass-order/colors', query})

        this.setState({ colors })
    }


    render() {
        if (!this.state.colors)
            return <div className="waiting"></div>

        const colors = this.state.colors.map((color, index) => 
            <li key={ index } className="list-group-item">
                { color.name }
                <button className="btn btn-success pull-right" onClick={ () => this.props.onColorSelected(color) }>Choisir</button>
                <div className="clearfix"></div>
            </li>)

        return <ul className="list-group">{ colors }</ul>
    }
}